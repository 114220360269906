import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('klarway');

export class Klarway extends Api {
    constructor(http, config = {}) {
        super(http, url, null, config);
    }


    async launchStudent(user, key) {
        const uri = this._uriConstructor(null, 'consultRegistry');
        return this._http.get(`${uri}?user=${user}&key=${key}`, this._config);
    }

    async launchFacialRecognition(user, key, exam) {
        const uri = this._uriConstructor(null, 'blFacialRecognition');
        const config = {...this._config, timeout: 600000};
        return this._http.get(`${uri}?user=${user}&key=${key}&exam=${exam}`, config);
    }

    async launchExam(user, key) {
        const uri = this._uriConstructor(null, 'blPlayExam');
        const config = {...this._config, timeout: 6000000};
        return this._http.get(`${uri}?user=${user}&key=${key}`, config);
    }

    async finalizeExam(qualification, key, examStatus, force) {
        const uri = this._uriConstructor(null, 'blFinalizeExam');
        return this._http.get(`${uri}?qualification=${qualification}&key=${key}&examStatus=${examStatus}&force=${force}`, this._config);
    }


}
