import Constants from '@/plugins/constants'

export default {

  // common mutations
  TOGGLE_POPUP (state, isOpen) {
    state.openPopUp = isOpen;
  },
  OPEN_POPUP (state, object) {
    state.openPopUp = true;
    state.alertPopUp = object
  },
  CLOSE_POPUP (state) {
    state.openPopUp = false;
    state.alertPopUp = null
  },
  SET_USERDATA (state, userData) {
    state.userData = userData
  },
  SET_LOGGED_USERDATA (state, userData) {
    state.loggedUserData = userData
  },
  CLEAR_USERDATA (state) {
    state.userData = {}
  },
  UPDATE_AVATAR (state) {
    state.userData.userData.avatar = `${Constants.URL_DATA_SERVER}/users/${state.userData.id}/avatar?${new Date().getTime()}`
  },
  SET_LOGOIMAGE (state) {
    state.configurations.logoPath = `${Constants.URL_DATA_SERVER}/configurations/logo-image?${new Date().getTime()}`
  },
  SET_USERDEFAULT (state) {
    state.configurations.userDefault = `${Constants.URL_DATA_SERVER}/configurations/default-avatar?${new Date().getTime()}`
  },
  SET_LOGINIMAGE (state) {
    state.configurations.loginPath = `${Constants.URL_DATA_SERVER}/configurations/login-image?${new Date().getTime()}`
  },
  UPDATE_CURRENT_USER_ROLES (state, data) {
    state.userData.roles[data.roleToDelete] = data;
  },
  SET_LOADING_DRAWER (state, data) {
    state.loading.drawer = data
  },
  INCREASE_LOADING (state) {
    state.loading.counter += 1
  },
  DECREASE_LOADING (state) {
    if (state.loading.counter > 0) state.loading.counter -= 1
  },
  SET_KLARWAY_PROCTORING (state, isKlarway) {
    state.isKlarway = isKlarway
  },
  SET_HASH (state, hash) {
    state.hash = hash
  },
  SET_USER_PERMISSIONS(state, userData) {
    const data = {};

    if (userData.roles && Array.isArray(userData.roles)) {
      userData.roles.forEach(role => {

        if (role.name == 'Administrador' || role.name == 'Revisión examen') state.isAdmin = true

        if (!Array.isArray(role.applications)) return;
        role.applications.forEach(application => {
          if (application.name == 'Exámenes') {

            if (!Array.isArray(application.modules)) return;
            application.modules.forEach(module => {
              if (data[module.id] === undefined) data[module.id] = {
                NAME: module.name,
                STATUS: module.status
              }
              else if (!data[module.id].STATUS) data[module.id].STATUS = module.status;

              if (!Array.isArray(module.permissions)) return;
              module.permissions.forEach(permission => {
                if (data[module.id][permission.name] !== true) data[module.id][permission.name] = permission.status
              });

              if (module.children && module.children.length) {
                module.children.forEach(subModule => {
                  if (data[subModule.id] === undefined) data[subModule.id] = {
                    NAME: subModule.name,
                    STATUS: module.status && subModule.status
                  }
                  else if (!data[subModule.id].STATUS) data[subModule.id].STATUS = module.status && subModule.status;

                  if (!Array.isArray(subModule.permissions)) return;
                  subModule.permissions.forEach(permission => {
                    if (data[subModule.id][permission.name] !== true) data[subModule.id][permission.name] = permission.status
                  });
                });
              }
            });
          }
        });
      });
    }

    state.permissions = data
  }

}
