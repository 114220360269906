const appBarTranslations = {
  es: {
    navigationDrawerApps: {
      'title': 'Aplicaciones',
    },
    profileDropdown: {
      'title': 'Mi cuenta',
      'notifications': 'Notificaciones',
      'logout': 'Cerrar sesión',
      'klarway': 'Editar registro'
    },
    helpMenu: {
      'helpSection': {
        'title': 'Ayuda',
        'items': {
          'documentation': 'Documentación',
          'news': 'Novedades',
          'about': 'Acerca de SIS',
          'shortcuts': 'Atajos de teclado'
        }
      },
      'legalSection': {
        'title': 'Asuntos legales',
        'items': {
          'privacyPolicy': 'Políticas de privacidad',
          'termsConditions': 'Términos y condiciones'
        }
      }
    }
  },

  en: {
    navigationDrawerApps: {
      'title': 'Applications',
    },
    profileDropdown: {
      'title': 'My account',
      'notifications': 'Notifications',
      'logout': 'Logout'
    }
  }
};

export default appBarTranslations




