import trae from 'trae'

import constants from './constants'

const ippService = trae.create({
  baseUrl: constants.URL_DATA_SERVER,

});

export default ippService
