import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('academic');

export class Subjects extends Api {
  constructor(http, config = {}) {
    super(http, url, 'subjects', config);
  }

  async filterByDepartment(departmentId, config = {}) {
    config = { ...this._config, ...config };
    return this._http.get(`${this._baseUrl}/subjects-by-department/${departmentId}`, {}, config);
  }
}
