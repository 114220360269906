<template>
    <div class="container-fluid">
        <div class="first-row">
            <StudentInformation v-if="student && exam.subject" :student="student" :subject="exam.subject"></StudentInformation>
        </div>
        <div class="second-row">
            <Questions
                v-if="exam.enunciados && exam.enunciados.length"
                :questions.sync="exam.enunciados"
                :totalQuestions="totalQuestions"
                :totalViewedQuestions.sync="viewed"
                :actualQuestion.sync="actualQuestion"
                @checkSavedAnswer="checkSavedAnswer"
            ></Questions>
            <GraphicQuestions
                v-if="exam.enunciados && exam.enunciados.length"
                :questions.sync="exam.enunciados"
                :actualQuestion.sync="actualQuestion"
                :assestmentTime="exam.tiempoRestante"
                :disableSaveButton="disableSaveButton"
                @checkSavedAnswer="checkSavedAnswer"
                @exams-finish="endExam"
                @current-time="($event) => {currentTime = $event}"
                @time-over="endExam"
            ></GraphicQuestions>
        </div>
        <div class="third-row">
            <Answers
                ref="answers"
                v-if="selectedQuestion"
                :current-time="currentTime"
                :question.sync="selectedQuestion"
                @saving-answers="($event) => { overlay = $event }"
                @next-question="goToNextQuestion"
            ></Answers>
        </div>
        <v-overlay :value="overlay">
            <h2 class="loading-message">{{ $t('modules.exams.assestment.loading.message') }}</h2>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <AlertPopup
            :active="alert.active"
            :title="alert.title"
            :content="alert.content"
            :actionPrimary="alert.actionPrimary"
            :actionSecondary="alert.actionSecondary"
            :icon="alert.icon"
            :color="alert.color"
        ></AlertPopup>
    </div>
</template>

<script>
    import { $exams, $exam } from '../Services';
    import moment from 'moment'
    //import AuthService from '@/plugins/auth.service'
    import { mapGetters } from 'vuex';

    export default {
        components: {
            AlertPopup: () => import('../Components/AlertPopup.vue'),
            StudentInformation: () => import('../Components/StudentInformation.vue'),
            GraphicQuestions: () => import('../Components/GraphicQuestions.vue'),
            Questions: () => import('../Components/Questions.vue'),
            Answers: () => import('../Components/Answers.vue'),
        },
        data() {
            return {
                student: {},
                exam: {},
                viewed: 0,
                actualQuestion: 0,
                selectedQuestion: null,
                //tokenInterval: null,
                overlay: true,
                index: 0,
                drawer: null,
                header: null,
                currentTime: 0,
                alert: {
                    active: false,
                    title: null,
                    content: null,
                    actionPrimary: null,
                    actionSecondary: null,
                    icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                    color: 'primary',
                },
                disableSaveButton: false
            }
        },
        watch: {
            actualQuestion() {
                this.index = this.exam.enunciados.findIndex((question) => question.id === this.actualQuestion);
                this.selectedQuestion = this.exam.enunciados[this.index];
            }
        },
        methods: {
            checkSavedAnswer(callback) {
                const question = this.exam.enunciados.find((question) => question.status.actual);
                let edited = false
                question.respuestas.forEach(res => {
                    if (res.elegida != res.original) edited = true
                    if (edited) return false
                })
                if (edited) {
                    this.alert.title = this.$t('modules.exams.assestment.answers.unsaved_title')
                    this.alert.content = this.$t('modules.exams.assestment.answers.unsaved_content')
                    this.alert.actionPrimary = {text: this.$t('actions.save'), callback: async () => {
                        this.alert.active = false
                        question.respuestas.forEach(res => { res.original = res.elegida })
                        await this.$refs.answers.saveAnswers(true)
                        callback()
                    }}
                    this.alert.actionSecondary = {text: this.$t('actions.not_save'), callback: async () => {
                        this.alert.active = false
                        await question.respuestas.forEach(res => { res.elegida = res.original })
                        callback()
                    }}
                    this.alert.active = true
                } else {
                    callback()
                }
            },
            buildStudentFullName(student) {
                return `${student.name.charAt(0).toUpperCase() + student.name.slice(1)} ${student.lastname.charAt(0).toUpperCase()}${student.lastname.slice(1)}`
            },
            goToNextQuestion() {
                const answers = [...this.exam.enunciados];
                const newIndex = (this.index + 1 === this.exam.enunciados.length) ? this.index : this.index + 1;

                answers[this.index].status.actual = false;
                answers[newIndex].status.actual = true;
                answers[newIndex].status.viewed = true;

                this.actualQuestion = answers[newIndex].id;
                this.exam.enunciados = [...answers];
                this.$scrollTo(`#question-${newIndex}`, 500, {container: '#scrollable-questions', easing: 'ease-in'})
            },
            async endExam() {
                if (this.disableSaveButton) return false
                this.disableSaveButton = true

                //clearInterval(this.tokenInterval)
                if (this.isAdmin) {
                    this.$router.push(`/exam/${this.exam.id}/review`);
                    return false
                }

                this.$ga.event('Examenes Live', 'Finalizar examen', 'Examen abierto', 0)
                this.exam.tiempoRestante = moment.duration(this.currentTime).asMinutes();
                this.exam.horaFin = moment().format('HH:mm:ss');
                window.localStorage.setItem('exam', JSON.stringify(this.exam));

                try {
                    await $exams.update(this.exam.id, {}, 'finalize');
                    this.$router.push(`/exam/${this.exam.id}/review`);
                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: error.codeMeaning,
                        content: this.$t('modules.exams.assestment.popUp.errorFinishingExam.content'),
                        actionSecondary: {text: this.$t('modules.exams.assestment.popUp.errorFinishingExam.buttonSecondary'), callback() {}},
                        icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                        color: 'primary',
                    });
                    this.$router.push(`/exams-available`);
                } finally {
                    this.disableSaveButton = false
                }
            }/*,
            refreshToken() {
                this.tokenInterval = setInterval(() => {
                    AuthService.refreshToken()
                }, 600000);
            },*/
        },
        computed: {
            totalQuestions() {
                return this.exam.enunciados ? this.exam.enunciados.length : 0;
            },
            ...mapGetters({
               isKlarway: 'commons/isKlarway',
               isAdmin : 'commons/isUserAdmin',
               studentId: 'commons/getStudentId',
               studentUserData: 'commons/getStudentData',
             })
        },
        async mounted() {
            try {
                if(!this.isKlarway) window.postMessage({monitoring: 'sumadiStart'});
                const data = {examId: this.$route.params.id, studentId: this.studentId}
                if (this.isAdmin) data.isAd = 'sis-admin'

                let exam = null
                for (let tries = 1; tries <= 2; tries++) {
                    try {                        
                         exam = await $exams.create(data)
                        break
                    } catch (error) {
                        if (tries == 2) throw error
                    }
                }

                exam.data.enunciados.sort((a, b) => {
                    return a.id - b.id;
                });

                this.exam = exam.data;
                this.exam.fecha = moment().format('YYYY-MM-DD');
                this.exam.horaInicio = moment().format('HH:mm:ss');
                this.student.fullName = this.buildStudentFullName(this.studentUserData);
        
                this.exam.enunciados.forEach((enunciado) => {
                    enunciado.status = {
                        answered: false,
                        viewed: enunciado.isRevisar && enunciado.isRevisar.toLowerCase() === 's',
                        toReview: enunciado.isRevisar && enunciado.isRevisar.toLowerCase() === 's',
                        actual: false,
                    };

                    enunciado.respuestas.forEach((respuesta) => {
                        respuesta.original = respuesta.elegida
                        if (respuesta.elegida && respuesta.elegida.toLowerCase() === 's') {
                            enunciado.status.answered = true;
                            enunciado.status.viewed = true;
                        }
                    });
                });

                //this.refreshToken()

            // TODO: (Diego) remove the next line
            } catch(error) {
                this.$store.dispatch('commons/openPopUp', {
                    title: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.exams.assestment.popUp.withToReviewOrNotAnswered.error'),
                    content: '',
                    actionSecondary: {text: this.$t('modules.exams.assestment.popUp.errorFinishingExam.buttonSecondary'), callback: async () => {
                       if (this.isKlarway) {
                        const resp =  await $exam.finalizeExam(0 , this.hash, 'desaprobado', 'true');
                        this.$store.dispatch('commons/initHash', resp.data.nk);
                        window.localStorage.setItem('hash', resp.data.nk);
                       } else{
                        window.postMessage({monitoring: 'sumadiStop'});
                       }
                        this.$router.push('/exams-available')} },
                    icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                    color: 'primary',
                });
                // this.$router.push(`/exams-available`);
                throw error
            } finally {
                this.overlay = false;
            }
        }
    }
</script>

<style lang="sass" scoped>
.scrollableContent
    height: 100vh

.container-fluid
    display: flex
    flex-direction: column
    padding: 1rem

    .first-row
        display: flex
        flex-direction: column
    .second-row
        display: flex
        flex-direction: row
        padding: .5rem 0

        div:first-child
            width: 60%

        div:last-child
            width: 40%

    .loading-message
        position: relative
        left: -4rem
</style>
