const alertPopupTranslations = {
  es: {
    moreItemsSingular: 'item más...',
    moreItemsPlural: 'items más...',
    defaultMessage: 'Se ha producido un error inesperado',
  },

  en: {
    moreItemsSingular: 'more item...',
    moreItemsPlural: 'more items...',
  }
};

export default alertPopupTranslations
