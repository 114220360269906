<template>
    <v-container class="d-flex fill-height align-center justify-center">
        <v-row>
            <v-col sm="6" class="mx-auto d-flex justify-center flex-column">
                <div class="mx-auto d-flex flex-column justify-center iconOpacity">
                    <div class="mx-auto d-flex flex-column justify-center iconContainer">
                        <v-icon color="white" size="72px">mdi-tools</v-icon>
                    </div>
                </div>
                <h1 class="text-center primary--text mt-4 mb-8">Este módulo se encuentra deshabilitado</h1>
                <p class="text-center mb-10">Estamos realizando tareas de mantenimiento, intenta de nuevo más tarde.</p>
                <div class="d-flex buttons-container">
                    <v-btn
                        large
                        rounded
                        text
                        color="primary"
                        class="my-2 px-8 text-uppercase"
                        @click="toHome"
                    >Volver</v-btn>
                    <v-btn
                        large
                        rounded
                        color="primary"
                        class="my-2 px-8 text-uppercase"
                        @click="logout"
                    >Cerrar Sesión</v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="sass" scoped>
.iconOpacity
    width: 182px
    height: 182px
    border-radius: 50%
    background-color: rgba(62, 80, 255, 0.1) !important
.iconContainer
    width: 120px
    height: 120px
    border-radius: 50%
    background-color: #3e50ff
h1
    line-height: 2rem
    font-family: "DM Sans", "Roboto" !important
.buttons-container
    justify-content: space-around
</style>

<script>
    export default {
        methods: {
            logout () {
                this.$auth.logout();
            },
            toHome () {
                this.$router.replace('/')
            }
        }
    };
</script>