<template>
  <div>
    <template v-if="$v.passInputs.email.input.$anyDirty">
      <v-alert
        text
        dense
        color="error"
        :value="!$v.passInputs.email.input.required"
      >{{ $t('modules.login.recovery.password.form.validations.emailRequired') }}</v-alert>

      <v-alert
        text
        dense
        color="error"
        :value="!$v.passInputs.email.input.email"
      >{{ $t('modules.login.recovery.password.form.validations.emailInvalid') }}</v-alert>
      <v-alert
        text
        dense
        color="error"
        :value="!$v.passInputs.email.input.exist && !$v.passInputs.email.input.$pending"
      >{{ $t('modules.login.recovery.password.form.validations.emailNonExistent') }}</v-alert>
    </template>
    <v-text-field
      v-model="passInputs.email.input"
      :label="$t('modules.login.recovery.password.form.labels.email')"
      outlined
      type="text"
      autocomplete="email"
      :success="!$v.passInputs.email.input.$invalid"
      :error="$v.passInputs.email.input.$anyError"
      :loading="$v.passInputs.email.input.$pending"
      @blur="$v.passInputs.email.input.$touch()"
    ></v-text-field>
    <div   class="checks-content pb-4">
      <h6   class="inputs-check body-1 mt-2">{{ $t('modules.login.recovery.password.form.labels.checksTitle') }}</h6>
      <v-checkbox
        v-model="passInputs.checkSMS"
        color="primary"
         style="transform: scale(1.4);"
        :error="$v.passInputs.checkSMS.$error"
        class="ma-0"
        :label="$t('modules.login.recovery.password.form.labels.checkSMS')"
        @change="()=>{$v.passInputs.checkSMS.$touch(); $v.passInputs.checkMail.$touch()}"
      ></v-checkbox>
      <v-checkbox
        v-model="passInputs.checkMail"
        color="primary"
         style="transform: scale(1.4);"
        :error="$v.passInputs.checkMail.$error"
        class="ma-0"
        :label="$t('modules.login.recovery.password.form.labels.checkMail')"
        @change="()=>{$v.passInputs.checkSMS.$touch(); $v.passInputs.checkMail.$touch()}"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>


import Constants from '@/plugins/constants'
import { email, required } from 'vuelidate/lib/validators'

export default {
  name: 'RecoveryPassForm',
  props: ['passInputs'],
  data () {    return {
      //
    }  },
  validations: {
    passInputs: {
      email: {
        input: {
          required,
          email,
          exist (value) {
            return new Promise((resolve) => {
              if (/.+@.+\..{2,}/.test(value)) {
                const body = { username: value, bySms: false, byMail: false };
                fetch(`${Constants.URL_DATA_SERVER  }/recover-pass`, Constants.getBasicOptions(body, Constants.HTTP_METHOD_POST))
                  .then(data => {
                    if (data.status == 404) {
                      resolve(false);
                    } else {
                      resolve(true)
                    }
                  })
              } else {
                this.passInputs.email.valid = false;
                resolve(true);
              }
            })
          },

        }
      },
      checkSMS: {
        checked (val) {
          return this.passInputs.checkMail || val;
        },
      },
      checkMail: {
        checked (val) {
          return this.passInputs.checkSMS || val;
        },
      }
    }
  },
  watch: {
    // cannot be an arrow-function
    '$v.passInputs.email.input.exist': function (value) {
      this.passInputs.email.valid = value && this.$v.passInputs.email.input.required && this.$v.passInputs.email.input.email;
    }
  }
};
</script>

<style scoped lang="sass">
.inputs-check
 position: relative
 right: 80px
 margin-bottom:30px
.checks-content
  margin-left: 80px 
.v-messages
    display: none
</style>
