import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('core');

export class CitiesWithState extends Api {
  constructor(http, config = {}) {
    super(http, url, 'cities-with-state', config);
  }
}
