<template>
    <div>
        <v-text-field
            v-model="passInputs.newPass"
            :error-messages="newPassErrors"
            :label="$t('modules.login.recovered.password.form.labels.newPass')"
            outlined
            required
            minlength="8"
            maxlength="28"
            :append-icon="hidePass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="hidePass ? 'text' : 'password'"
            @click:append="hidePass = !hidePass"
            @input="$v.passInputs.newPass.$touch"
            @blur="$v.passInputs.newPass.$touch"
        ></v-text-field>
        <v-text-field
            v-model="passInputs.newPassRepeated"
            :error-messages="repeatedPassErrors"
            :label="$t('modules.login.recovered.password.form.labels.repeatPass')"
            outlined
            required
            minlength="8"
            maxlength="28"
            :append-icon="hidePassRepeated ? 'mdi-eye' : 'mdi-eye-off'"
            :type="hidePassRepeated ? 'text' : 'password'"
            @click:append="hidePassRepeated = !hidePassRepeated"
            @input="$v.passInputs.newPassRepeated.$touch"
            @blur="$v.passInputs.newPassRepeated.$touch"
        ></v-text-field>
        <div class="d-fex flex-column">
            <v-progress-linear :color="securityProgessBar.color" :value="progressBarValue"></v-progress-linear>
            <div class="d-flex justify-space-between">
                <span class="caption">{{ securityProgessBar.label }}</span>
                <span class="caption">{{ securityProgessBar.category }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { maxLength, minLength, required } from 'vuelidate/lib/validators'

    export default {
        name: 'RecoveryPassForm',
        props: {
            passInputs: Object,
        },
        data() {
            return {
                hidePass: false,
                hidePassRepeated: false,
                securityProgessBar: {
                    color: 'error',
                    label: this.$t('modules.login.recovered.password.securityProgressBar.label'),
                    category: this.$t('modules.login.recovered.password.securityProgressBar.categories.down')
                },
            }
        },
        methods: {
            validatePassSecurity () {
                this.$emit('validatePassSecurity')
            }
        },
        computed: {
            progressBarValue () {
                let val = 0;
                if (this.$v.passInputs.newPass.required) ++val;
                if (this.$v.passInputs.newPass.strongLevel) ++val;
                if (this.$v.passInputs.newPass.mediumLevel) ++val;
                return (val / 3) * 100;
            },
            newPassErrors () {
                const errors = [];
                if(!this.$v.passInputs.newPass.$anyError) return errors;
                if (!this.$v.passInputs.newPass.required) errors.push(this.$t('modules.login.recovered.password.form.validations.passRequired'));
                if (!this.$v.passInputs.newPass.minLength) errors.push(this.$t('modules.login.recovered.password.form.validations.passLength'));
                return errors;
            },
            repeatedPassErrors () {
                const errors = [];
                if(!this.$v.passInputs.newPassRepeated.$anyError) return errors;
                if (!this.$v.passInputs.newPassRepeated.required) errors.push(this.$t('modules.login.recovered.password.form.validations.passRequired'));
                if (!this.$v.passInputs.newPassRepeated.sameAsNewPass) errors.push(this.$t('modules.login.recovered.password.form.validations.passEqual'));
                return errors;
            }
        },
        watch: {
            'progressBarValue': function (value) {
                const tmp = Math.round((value * 3) / 100);
                if (tmp === 1) {
                    this.securityProgessBar.color = 'error';
                    this.securityProgessBar.category = this.$t('modules.login.recovered.password.securityProgressBar.categories.down');
                }
                if (tmp === 2) {
                    this.securityProgessBar.color = 'warning';
                    this.securityProgessBar.category = this.$t('modules.login.recovered.password.securityProgressBar.categories.middle')
                }
                if (tmp === 3) {
                    this.securityProgessBar.color = 'success';
                    this.securityProgessBar.category = this.$t('modules.login.recovered.password.securityProgressBar.categories.high');
                }
            }
        },
        validations: {
            passInputs: {
                newPass: {
                    required,
                    minLength: minLength(8),
                    maxLength: maxLength(28),
                    strongLevel (val) {
                        let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");     // eslint-disable-line
                        return strongRegex.test(val)
                    },
                    mediumLevel (val) {
                        let mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");     // eslint-disable-line
                        return mediumRegex.test(val);
                    }
                },
                newPassRepeated: {
                    required,
                    sameAsNewPass (value) { return value === this.passInputs.newPass }
                }
            }
        }
    };
</script>
