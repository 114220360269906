///////////////////////////
// DON'T TOUCH THIS FILE //
///////////////////////////


// Import vue and vuex

import Vue from 'vue'
import Vuex from 'vuex'

// Import modules stores

import modules from './modules.conf'


// Define store

Vue.use(Vuex)


// Export

const store = new Vuex.Store({ modules })

export default store
