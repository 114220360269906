<template>
  <v-container class="d-flex fill-height align-center justify-center">
    <v-row>
      <v-col sm="6" class="mx-auto d-flex justify-center flex-column">
        <v-icon color="warning" size="154px">mdi-shield-alert</v-icon>
        <h6 class="headline text-center mt-4 mb-8">No tienes permisos para realizar esta acción</h6>
        <p class="text-center mb-10">Ponte en contacto con el equipo de IPP para más información.</p>
        <div class="d-flex justify-center">
          <v-btn
            large
            rounded
            text
            color="primary"
            class="my-2 px-8 text-uppercase"
            @click="toHome"
          >Volver</v-btn>
          <v-btn
            large
            rounded
            color="primary"
            class="my-2 px-8 text-uppercase"
            @click="logout"
          >Cerrar Sesión</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    logout () {
      this.$auth.logout();
    },
    toHome () {
      this.$router.push('/')
    }
  }
};
</script>
