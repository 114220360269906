import ExamsAvailables from '../ExamsAvailables.vue'
import ExamsAvailablesByStudent from '../Views/AvailablesByStudent.vue';
import Empadronamiento from '../Views/Empadronamiento.vue';

const ExamsAvailablesRoute = {
    path: '/exams-available',
    meta: {
        name: {
            es: 'Exámenes inscritos',
            en: 'Available exams',
        }
    },
    component: ExamsAvailables,
    children: [
        {
            path: '',
            component: ExamsAvailablesByStudent,
            meta: { name: 'Index', path: 'available_exams', permission: 'READ' }
        },
        {
            path: 'empadronamiento',
            component: Empadronamiento,
            meta: { name: 'Index', path: 'available_exams', permission: 'READ' }
        },
    ]
};

export default ExamsAvailablesRoute
