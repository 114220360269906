<template>
  <v-dialog v-model="dialog" scrollable width="600px" v-closable="{handler: 'closeDialog'}">
    <!-- <template v-slot:activator="{ on }">
      <v-btn color="primary" dark v-on="on">Acerca de SIS</v-btn>
    </template>-->
    <v-card class="pa-10 py-0">
      <div class="subtitle-1 primary--text my-6">Atlassian JIRA y plugins de JIRA</div>
      <v-row>
        <v-col sm="10">
          <!--antes sm="6" -->
          <h4 class="title font-weight-bold mb-1">Créditos de SIS</h4>
          <h4 class="title font-weight-bold mb-1">Información de Licencia sobre SIS</h4>
          <h6 class="body-2">SIS vf917172d</h6>
        </v-col>
        <!-- <v-col sm="5">
            <v-img></v-img>
        </v-col>-->
      </v-row>

      <v-card-text class="px-0 py-2 my-4" style="height: 600px;">
        <div class="body-2">
          Derechos de Autor © 2002 - 2019 Atlassian Corporation Pty Ltd.
          <br />
          <br />El uso de este producto está sujeto a los términos y condiciones fijados en el Atlassian End User Agreement, a menos que ahí se indique lo contrario.
          <br />
          <br />Este producto incluye software desarrollado por la Apache Software Foundation.
        </div>
        <div class="title mt-6 mb-2">Información de licencia de plugins de SIS</div>
        <div class="body-1">SIS Service Desk Application 3.3.0-OD</div>
        <div
          class="caption grey--text text--darken-2 mb-4"
        >Copyright © 2002 - 2018 Atlassian Corporation Pty Ltd.</div>
        <div class="body-2">
          El uso de este plugin está sujeto a las condiciones del Acuerdo de usuario final de Atlassian, a menos que allí se estipule lo contrario.
          Este plugin incluye software diseñado por la Apache Software Foundation.
          Este plugin también incluye códigos escritos por otros terceros.
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn class="primary--text text--darken-3 caption" text @click="closeDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>

