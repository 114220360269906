<template>
    <router-view></router-view>
</template>

<script>
    export default {
            name: 'ExamsAvailables',
            created() {
                const code = this.$route.query?.code

                if (this.$route.query?.code) {
                    this.$auth.validateCognito('user', 'password', true, code)
                }

                setTimeout(()=>{
                    this.$auth.validateTokenCognito()
                }, 3000)
            }
    }
</script>