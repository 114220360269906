import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('klarway');

export class Exam extends Api {
    constructor(http, config = {}) {
        super(http, url, 'exam', config);
    }

    async launchExam(user, key) {
        const uri = this._uriConstructor(null, 'playExam');
        const config = {...this._config, timeout: 6000000};
        return this._http.get(`${uri}?user=${user}&key=${key}`, config);
    }

    async finalizeExam(qualification, key, examStatus, force) {
        const uri = this._uriConstructor(null, 'finalizeExam');
        return this._http.get(`${uri}?qualification=${qualification}&key=${key}&examStatus=${examStatus}&force=${force}`, this._config);
    }

}