<template>
    <v-container
        class="fullHeightViewport backgroundLogin pa-0"
        fluid
        :style="`
            background-image: url(${appPath});
            background-size: 66vw 100vh;
            background-position:  right  center ;`"
    >
        <v-container class="container-custom pa-0">
            <v-row no-gutters>
                <v-col cols="10" md="4" sm="6" class="mx-auto mx-sm-0">
                    <router-view></router-view>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import Constants from '@/plugins/constants'
    import { mapGetters } from 'vuex';
    export default {
        name: 'Login',
        components: {},
        data () {
            return {
                url_data_server: Constants.URL_DATA_SERVER,
            }
        },
        computed: {
            ...mapGetters({
                appPath: 'commons/getApplicationImageUrl',
            }),
        }
    }
</script>

<style lang="sass" scoped>
.loginFormCard
    position: relative
    max-width: 400px
    margin: 0 auto
    display: flex
    flex-direction: column
    justify-content: center
    .recovery-btn
        margin-top: -24px
        position: relative
        z-index: 2
        h6
            font-weight: 300
            text-transform: unset
    &__logo
        position: relative
        top: -3rem
        left: 0
    .v-alert
        line-height: 1.2
    .v-input
        input
            -webkit-box-shadow: 0 0 0px 1000px #fff inset !important
            &:hover, &:focus, &:active
                -webkit-box-shadow: 0 0 0px 1000px #fff inset !important
    .v-input--checkbox
        .v-input__slot
            margin-bottom: 0 !important
        .v-messages
            display: none

    .v-text-field
        .v-input__slot
            input
                -webkit-box-shadow: 0 0 0px 1000px #fff inset !important
                &:hover, &:focus, &:active
                    -webkit-box-shadow: 0 0 0px 1000px #fff inset !important
@media (max-width: 768px)
    .backgroundLogin
        background-image: unset !important
    .loginFormCard
        justify-content: flex-start
        &__logo
            top: 0 !important
</style>