<template>
    <v-container fluid class="container-background fullHeight px-10 py-8 d-flex align-center">
        <v-container v-if="!isWarning">
            <v-container>
                <v-row v-if="loading">
                    <v-col cols="10" class="mx-auto mb-10 text-center">
                        <v-progress-circular
                            :size="50"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                        <h1 class="headline primary--text mt-4" >{{ $t('modules.examsAvailables.loading') }}</h1>
                    </v-col>
                </v-row>
                <div v-else-if="!isRegistrered">
                    <v-row>
                        <v-col class="mx-auto mb-10 text-center">
                            <img width="250" height="250" :src="require('@/assets/klarway/Not_Registered.svg')" />
                            <h1 class="headline grey--text text--darken-2 mt-4" >{{ $t('modules.examsAvailables.empadronamiento.not_registered.text') }}</h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-center align-center">
                            <v-btn 
                                rounded 
                                right 
                                color="primary"
                                @click="register()"
                                >
                                    {{ $t('modules.examsAvailables.empadronamiento.not_registered.button') }}
                                </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <div v-else-if="availableExams">
                    <v-row>
                        <v-col sm="11" class="mx-auto pl-0">
                            <h2 class="title grey--text text--darken-3">{{ $t('modules.examsAvailables.students.headline.title') }}</h2>
                            <v-divider class="mb-0"></v-divider>
                        </v-col>
                    </v-row>
                    <v-row v-for="(career, index) of careers" :key="`career-${index}`">
                        <v-col sm="11" class="mx-auto pl-0">
                            <h1 class="headline primary--text">{{ $t('modules.examsAvailables.students.headline.name', {career: career.degree}) }}</h1>
                        </v-col>
                        <v-col sm="11" class="mx-auto px-0">
                            <v-card class="mx-auto elevation-3 py-3 px-10" v-for="(exam, i) of career.requests" :key="`exam-${i}`">
                                <v-row>
                                    <v-col sm="3" class="align-self-center">
                                        <span class="font-weight-bold grey--text text--darken-2">{{ exam.subject }}</span>
                                    </v-col>
                                    <v-col sm="3" class="align-self-center text-center">
                                        <div>
                                            <span class="caption font-weight-bold grey--text text--darken-2 d-block">{{ $t('modules.examsAvailables.students.examcard.date') }}</span>
                                            <span class="caption font-weight-bold grey--text text--darken-2 d-block">{{ exam.date }}</span>
                                        </div>
                                        <!-- v-if ="exam.finished" -->
                                        <!-- <span v-else class="caption font-weight-bold grey--text text--darken-2">{{ $t('modules.examsAvailables.students.examcard.type') }}</span> -->
                                    </v-col>
                                    <v-col sm="2" class="align-self-center text-center">
                                        <span class="caption font-weight-bold grey--text text--darken-2">{{ $t('modules.examsAvailables.students.examcard.instance') }}</span>
                                    </v-col>
                                    <v-col sm="4" class="align-self-center text-right">
                                        <span class="orange--text text--darken-1 font-weight-bold" v-if="!exam.enabled"><v-icon class="mr-2" color="warning">mdi-clock-outline</v-icon>&nbsp;{{ $t('modules.examsAvailables.students.examcard.waiting_habilitation') }}</span>
                                        <span class="orange--text text--darken-1 font-weight-bold" v-else-if="isAdmin && !exam.initialized"><v-icon class="mr-2" color="warning">mdi-clock-outline</v-icon>&nbsp;{{ $t('modules.examsAvailables.students.examcard.not_initialized') }}</span>
                                        <span class="orange--text text--darken-1 font-weight-bold" v-else-if="isAdmin && !exam.finished"><v-icon class="mr-2" color="warning">mdi-clock-outline</v-icon>&nbsp;{{ $t('modules.examsAvailables.students.examcard.not_finished') }}</span>
                                        <span class="green--text text--darken-1 font-weight-bold" v-else-if="!isAdmin && exam.finished">{{ $t('modules.examsAvailables.students.examcard.finished') }}</span>
                                        <v-btn
                                            v-else-if="exam.proctoringType.value == 271 ? true : exam.proctoringType.value == 272 ? mostrarBotonRendir : false "
                                            rounded
                                            right
                                            color="primary"
                                            @click="initExam(exam)"
                                        >{{ (!isAdmin) ? $t('modules.examsAvailables.students.examcard.button') : $t('modules.examsAvailables.students.examcard.review') }}</v-btn>
                                        <v-btn
                                            v-else-if="!mostrarBotonRendir"
                                            rounded
                                            right
                                            color="primary"
                                            @click="habilitarExamen(exam)"
                                        >{{ (!isAdmin) ? $t('modules.examsAvailables.students.examcard.habilitar') : $t('modules.examsAvailables.students.examcard.review') }}</v-btn>
                                        <v-btn
                                            v-if="isAdmin && exam.enabled && exam.initialized && !exam.finished"
                                            class="ml-4"
                                            :loading="loadingFinalize"
                                            rounded
                                            right
                                            color="primary"
                                            @click="finalizarExamenPopup(exam)"
                                        >{{ $t('modules.examsAvailables.students.examcard.finalize') }}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="11" class="mx-auto px-0">
                            <v-card class="mx-auto mt-10 py-5 px-4" outlined>
                                <v-card-text>
                                    <div class="title primary--text">
                                        <v-icon class="mr-2" color="#0dcfda">mdi-alert-circle</v-icon>{{ $t('modules.examsAvailables.terms.title') }}
                                    </div>
                                    <div class="title primary--text mt-3">
                                        <v-icon class="mr-2" color="#0dcfda">mdi-alert-circle</v-icon>{{ $t('modules.examsAvailables.terms.subtitle') }}
                                    </div>
                                    <div class="px-8">
                                        <v-list-item class="px-0" three-line>
                                            <v-list-item-content class="py-3">
                                                <v-list-item-subtitle class="py-2">{{ $t('modules.examsAvailables.terms.time_start') }}</v-list-item-subtitle>
                                                <v-list-item-subtitle class="py-2">{{ $t('modules.examsAvailables.terms.time_duration') }}</v-list-item-subtitle>
                                                <v-list-item-subtitle class="py-2">{{ $t('modules.examsAvailables.terms.time_aclaration') }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </div>
                                    <div class="title primary--text mt-3">
                                        <v-icon class="mr-2" color="#0dcfda">mdi-alert-circle</v-icon>{{ $t('modules.examsAvailables.terms.important') }}
                                    </div>
                                    <div class="px-8">
                                        <v-list-item class="px-0">
                                            <v-list-item-content class="pb-0">
                                                <v-list-item-subtitle>{{ $t('modules.examsAvailables.terms.text4') }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item class="px-0">
                                            <v-list-item-content class="pb-0">
                                                <v-list-item-title class="font-weight-bold">{{ $t('modules.examsAvailables.terms.approved') }}</v-list-item-title>
                                                <v-list-item-title class="font-weight-bold">{{ $t('modules.examsAvailables.terms.score') }}</v-list-item-title>
                                                <v-list-item-title class="font-weight-bold">{{ $t('modules.examsAvailables.terms.correct_answers') }}</v-list-item-title>
                                                <v-list-item-title class="font-weight-bold">{{ $t('modules.examsAvailables.terms.incorrect_answers') }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item class="px-0" three-line>
                                            <v-list-item-content class="pb-0">
                                                <v-list-item-subtitle class="custom-clamp">{{ $t('modules.examsAvailables.terms.before_exit') }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </div>
                                    <div class="title primary--text mt-3">
                                        <v-icon class="mr-2" color="#0dcfda">mdi-alert-circle</v-icon>{{ $t('modules.examsAvailables.terms.review.title') }}
                                    </div>
                                    <div class="px-8">
                                        <v-list-item class="px-0" three-line>
                                            <v-list-item-content class="py-3">
                                                <v-list-item-subtitle class="py-2 custom-clamp">{{ $t('modules.examsAvailables.terms.review.subtitle') }}</v-list-item-subtitle>
                                                <v-list-item-subtitle class="py-2 custom-clamp">{{ $t('modules.examsAvailables.terms.review.time') }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
                <v-row v-else>
                    <v-col cols="10" class="mx-auto mb-10 text-center">
                        <img width="120" height="120" :src="require('@/assets/examen.png')" />
                        <h1 class="headline primary--text mt-4" >{{ $t('modules.examsAvailables.noexam.title') }}</h1>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
        <SuccessMessage
            v-else-if="!loading"
            :type="successMessage.type"
            :title="successMessage.title"
            :subtitle="successMessage.subtitle"
            :actionPrimary="successMessage.actionPrimary"
            :actionSecondary="successMessage.actionSecondary"
            :loading="successMessage.loading"
        />
    </v-container>
</template>

<script>
    import { $exams, $exam, $user, $sumadi } from '../Services'
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import { mapGetters } from 'vuex'

    export default {
        name: 'AvailablesByStudent',
        components: {
            SuccessMessage,
        },
        data() {
            return {
                test: false,
                loadingFinalize: false,
                mostrarBotonRendir: false,
                hash: '',
                careers: [],
                intervalId: null,
                loading: true,
                isWarning: false,
                isRegistrered: true,
                checkRegistry: false,
                successMessage: {
                    loading: false,
                    type: 'warning',
                    title: this.$t('modules.examsAvailables.success_message.initialize.title'),
                    subtitle: this.$t('modules.examsAvailables.success_message.subtitle'),
                    actionPrimary: { text: this.$t('modules.examsAvailables.success_message.start'), callback () { } },
                    actionSecondary: { text: this.$t('modules.examsAvailables.success_message.cancel'), callback () { }},
                }
            }
        },
        computed: {
            availableExams() {
                let exams = false
                this.careers.forEach(career => {
                    if (career.requests.length) {
                        exams = true
                        return false
                    }
                });
                return exams
            },
            ...mapGetters({
                isKlarway: 'commons/isKlarway',
                userId: 'commons/getStudentUserID',
                studentId : 'commons/getStudentId',
                studentRegisters: 'commons/getStudentRegisters',
                userData : 'commons/getStudentData',
                isAdmin : 'commons/isUserAdmin',
                getHash : 'commons/getHash',
            })
        },
        mounted() {
            this.initApp()
        },
        methods: {
            async initApp() {
                if (this.isKlarway) {
                    this.hash = this.getHash;
                    // Esto es por si abren Klarway y ya tan logueados, no tienen token, tienen q loguearse de nuevo
                    if(this.hash == '' || this.hash == null) this.$router.push('/logout');

                    if (!this.isAdmin && this.userData && this.$route.query && this.$route.query.checkRegistry) {
                        const check = await this.checkEmpadronamiento();
                        if (!check) this.isRegistrered = false
                        this.checkRegistry = true
                    } else {
                        this.checkRegistry = true
                    }
                }
                                
                // Needed for Zendesk
                if (this.userData) {
                    const leg = this.studentRegisters.find(leg => leg.status = 'Cursando')
                    window.localStorage.setItem('name', JSON.stringify(`${this.userData.name} ${this.userData.lastname}`));
                    window.localStorage.setItem('email', JSON.stringify(this.userData.email));
                    window.localStorage.setItem('identification', JSON.stringify(this.userData.identification));
                    window.localStorage.setItem('codcli', leg ? JSON.stringify(leg.legajo) : '');
                }

                this.loadStudent()
            },
            finalizarExamenPopup(exam) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.examsAvailables.success_message.finalize_title'),
                    content: [{value: this.$t('modules.examsAvailables.success_message.finalize_text')}],
                    actionPrimary: { text: this.$t('modules.examsAvailables.students.examcard.finalize'), callback: () => { this.finalizarExamen(exam) } },
                    actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
                    icon: { color: 'warning', name: 'mdi-alert' },
                    color: 'primary',
                });
            },
            async finalizarExamen(exam) {
                try {
                    this.loadingFinalize = true
                    await $exams.update(exam.examId, {}, 'finalize');
                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: error.codeMeaning,
                        content: this.$t('modules.exams.assestment.popUp.errorFinishingExam.content'),
                        actionSecondary: {text: this.$t('actions.cancel'), callback() {}},
                        icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                        color: 'primary',
                    });
                } finally {
                    this.fetchRequest()
                    this.loadingFinalize = false
                }
            },
            initExam(exam) {
                if (this.isAdmin) return this.$router.push(`/exam/${exam.examId}`);

                if (!this.matchProctoring(exam)) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: `${this.$t('modules.examsAvailables.popUp.errorMatchProctoring.title')} ${this.isKlarway ? 'Sumadi' : 'Klarway'}`,
                        actionPrimary: { text: this.$t('actions.accept') },
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                    });
                    return false
                }
                const app = this.isKlarway ? 'klarway' : 'sumadi'
                this.successMessage.title = this.$t(`modules.examsAvailables.success_message.initialize.${app}.${this.isKlarway ? 'start_validation_' : ''}title`)
                this.successMessage.subtitle = this.$t(`modules.examsAvailables.success_message.initialize.${app}.${this.isKlarway ? 'start_validation_' : ''}subtitle`)
                this.successMessage.actionPrimary = { text: this.$t('modules.examsAvailables.success_message.start'), callback: () => { this.initialize(exam.examId) }}
                this.successMessage.actionSecondary = { text: this.$t('modules.examsAvailables.success_message.cancel'), callback: () => { this.isWarning = false }} 
                this.isWarning = true
            },
            async habilitarExamen(exam) {
                if (this.isAdmin) return this.$router.push(`/exam/${exam.examId}`);

                if (!this.matchProctoring(exam)) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: `${this.$t('modules.examsAvailables.popUp.errorMatchProctoring.title')} ${this.isKlarway ? 'Sumadi' : 'Klarway'}`,
                        actionPrimary: { text: this.$t('actions.accept') },
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                    });
                    return false
                }

                if (this.isKlarway && !this.isAdmin) {
                    if (!this.checkRegistry) return false
                    const check = await this.checkEmpadronamiento();
                    if (check) {
                        try {
                            // Si esta dentro del proctoring de klarway
                            const response2 = await $user.launchFacialRecognition(this.userId, this.hash, exam.examId);
                            if (response2.data.success == true) this.mostrarBotonRendir = true;
                        } catch (error) {
                            this.mostrarBotonRendir = false
                            throw error
                        }
                    }
                } else {
                    this.mostrarBotonRendir = true;
                }
            },
            async checkEmpadronamiento() {
                
                try {
                    const response = await $user.consultRegistry(this.userId, this.hash);
                    if (response.data.error) return false

                    return true

                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.exams.assestment.popUp.withToReviewOrNotAnswered.error'),
                        content: '',
                        actionSecondary: { text: this.$t('modules.exams.assestment.popUp.withToReviewOrNotAnswered.buttonSecondary'), callback() {} },
                        icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                        color: 'primary',
                    });
                    return false;
                }
            },
            async initialize(id) {
                try {
                    this.loading = !this.isKlarway
                    this.successMessage.loading = this.isKlarway

                    await $exams.update(id, {}, 'initialize');
                    if (this.isKlarway) {
                        // Si estoy en el proctoring de klarway 
                        await $exam.launchExam(this.userId, this.hash);

                        this.successMessage.title = this.$t('modules.examsAvailables.success_message.initialize.klarway.start_exam_title')
                        this.successMessage.subtitle = this.$t('modules.examsAvailables.success_message.initialize.klarway.start_exam_subtitle')
                        this.successMessage.actionPrimary = { text: this.$t('modules.examsAvailables.success_message.start'), callback: () => { this.checkExamStatus(id) }}
                        this.successMessage.actionSecondary = null
                    } else {
                        // Si estoy en el proctoring de sumadi 
                        await $exams.update(id, {}, 'initialize');
                        if (!this.isAdmin) {
                            const response = await $sumadi.launchStudent(id)
                            window.location.href = response.data.urlToRedirect
                        } else {
                            this.$router.push(`/exam/${id}`);
                        }
                    }
                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.exams.assestment.popUp.withToReviewOrNotAnswered.error'),
                        content: '',
                        actionSecondary: { text: this.$t('modules.exams.assestment.popUp.withToReviewOrNotAnswered.buttonSecondary'), callback() {} },
                        icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                        color: 'primary',
                    });
                } finally {
                    this.loading = false
                    this.successMessage.loading = false
                }
            },
            async checkExamStatus(id) {
                try {
                    this.successMessage.loading = true
                    await $exam.find('checkExamStatus', null, { params: { key: this.hash }})
                    this.$router.push(`/exam/${id}`)
                } catch (error) {
                    if (error.message && error.message == 'pending') {
                        setTimeout(() => { this.checkExamStatus(id) }, 3000)
                        return
                    }

                    // Error en validacion
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('alert'),
                        content: this.$t('modules.examsAvailables.success_message.initialize.klarway.validation_error'),
                        actionPrimary: { text: this.$t('actions.accept'), callback() { this.isWarning = false }},
                        icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                        color: 'primary',
                    });
                    this.successMessage.loading = false
                }
            },
            async loadStudent() {
                this.loading = true
                if (this.studentId) {
                    this.fetchRequest();
                    this.intervalId = setInterval(this.fetchRequest, 30000);
                } else {
                    this.loading = false
                    clearInterval(this.intervalId)
                }
            },
            async fetchRequest() {
                const careers = (await $exams.getExams(this.studentId, this.isAdmin)).data;
                careers.forEach(career => {
                    career.requests = career.requests.map(request => {
                        request.date = `${request.turnDayStart} ${request.turnStart}`
                        return request
                    })
                    career.requests.sort((a, b) => {
                        return new Date(b.date) - new Date(a.date);
                    });
                })
                this.careers = careers
                const enabled = this.careers.find(career => career.requests.find(exam => exam.enabled === true))
                if (enabled) clearInterval(this.intervalId)
                this.loading = false;
            },
             matchProctoring(exam){
                return (exam.proctoringType.value == (this.isKlarway ? 272 : 271))
            },
            async registryUser() {
                try{
                    const request = {
                        name : this.userData.name,
                        lastName : this.userData.lastname,
                        email : this.userData.email,
                        user : this.userData.identification,
                        id : this.userId,
                        dni: this.userData.identification
                    }
                    const response = await $user.saveUser(request);
                    return response;
                }
                catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.exams.assestment.popUp.withToReviewOrNotAnswered.error'),
                        content: 'Error al registrar usuario',
                        actionSecondary: { text: this.$t('modules.exams.assestment.popUp.withToReviewOrNotAnswered.buttonSecondary'), callback() {} },
                        icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                        color: 'primary',
                    });
                    throw error;
                }
            },
            async register(){
                await this.registryUser();
                this.$activatePadron();
                this.isRegistrered = true;
            }
                    
        },
        watch: {
            studentId() {
                this.loadStudent()
            }
        },
    };
</script>

<style lang="sass" scoped>
.container-background
    background-color: rgba(77, 148, 255, 0.08)

.custom-clamp
    -webkit-line-clamp: 3 !important
</style>
