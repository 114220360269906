<template>
    <div class="text-center">
        <v-list>
            <v-list-item-group>
                <v-list-item class="v-list-item--active white">
                    <v-icon class="mr-4">mdi-account-circle</v-icon>
                    <span class="body-1">{{ userInputs.email | partiallyHide }}</span>
                </v-list-item>
                <v-list-item @click="goToLogin">
                    <v-icon class="mr-4">mdi-account-outline</v-icon>
                    <span class="body-1 grey--text text--darken-1">{{ $t('modules.login.recovered.user.switchAccount') }}</span>
                </v-list-item>
                <v-divider></v-divider>
            </v-list-item-group>
        </v-list>
        <h4 class="body-1 pt-8">{{ $t('modules.login.recovered.user.form.title') }}</h4>
        <form @keyup.enter="logIn" class="pt-6" @submit.prevent="logIn">
            <v-text-field
                v-model="userInputs.password"
                :label="$t('modules.login.recovered.user.form.labels.password')"
                :append-icon="hidePass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="hidePass ? 'text' : 'password'"
                outlined
                required
                autocomplete="current-password"
                @click:append="hidePass = !hidePass"
            ></v-text-field>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'RecoveryUserForm',
        props: {
            userInputs: Object
        },
        data() {
            return {
                hidePass: false
            }
        },
        methods: {
            goToLogin () {
                this.$router.push('/login')
            },
            logIn () {
                this.$emit('recoveredAction');
            }
        },
        filters: {
            partiallyHide (value) {
                // user's mail hidden
                if (!value) return ''
                value = value.toString()

                const hidden = '****'
                let char = 2;
                while (value.charAt(char) != '@') {
                    char++;
                }

                return value.substr(0, 2) + hidden + value.substr(char);
            }
        }
    };
</script>