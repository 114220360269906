import camelCase from 'lodash.camelcase';
import http from '../plugins/http-client';

/**
 * @param  {object} files
 * @param  {string} findedExtention='.js'
 * @return {object}
 *
 * This function creates dinamically an object with
 * all files to exports based on the list of files
 * passed to it in the files param
 */
function autoImportModules(files, findedExtention = '.js') {
  const modules = {};

  files.keys().forEach((fileName) => {
    if (fileName !== `./index${findedExtention}`) {
      const regexp = new RegExp(`(\\.\\/|\\${findedExtention})`, 'g');
      const moduleName = camelCase(fileName.replace(regexp, ''));
      modules[moduleName] = files(fileName).default || files(fileName)
    }
  });

  return modules
}

/**
 * @param  {object} files
 * @return {object}
 *
 * This function instantiate dinamically every module
 * and creates an object to exports with all instances
 * based in the list of files passed to it in files param
 * it is used to create a microservice in api directory
 */
export function createMicroservice(files) {
  const modules = autoImportModules(files);
  const microservice = {};

  Object.keys(modules).forEach((resource) => {
    if (/Module/.test(modules[resource].toString())) {
      const className = `${resource.charAt(0).toUpperCase()}${resource.slice(1)}`;
      microservice[`$${resource}`] = new modules[resource][className](http);
    } else if(typeof modules[resource] === 'function') {
      microservice[`$${resource}`] = new modules[resource](http);
    } else {
      throw new Error(`${resource} file does not contain a module or a default export`)
    }
  });

  return microservice;
}

/**
 * @param  {object} files
 * @return {object}
 *
 * This function export an object with all entities exported from
 * other modules, it is used to integrate all microservices inside
 * api directory and export it like an api object with all services
 * used along the application
 */
export function integrateMicroservices(files) {
  const api = {};

  files.keys().forEach((fileName) => {
    if (fileName !== './index.js' && /(\/index.js$)/.test(fileName)) {
      let [,microservice,] = fileName.split('/');
      microservice = camelCase(microservice);
      api[`$${microservice}`] = files(fileName).default
    }
  });

  return api;
}
