<template>
  <v-sheet v-if="profileDropdown.open" v-closable="{handler: 'onClose'}" class="profileDropdown">
    <v-list class="pa-0">
      <v-list-item class="py-4 px-6">
        <v-avatar color="blue" size="32">
          <img :src="_self['commons/getUserData']['avatar']" />
        </v-avatar>
        <div class="d-flex flex-column pl-4">
          <div class="title">{{this['getFullName']}}</div>
        </div>
      </v-list-item>
      <v-divider></v-divider>
      <!-- <v-list two-line class="pa-0">
        <v-list-item click class="px-6">
          <v-list-item-icon class="my-auto pl-1">
            <v-icon> $notif-icon </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$t('components.appBar.profileDropdown.notifications')"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
      
      <v-list two-line class="pa-0" v-if="showKlarwayRegisterButton">
        <v-list-item @click="$activatePadron()" class="px-6 pb-2">
          <v-list-item-icon class="my-auto pl-1">
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('components.appBar.profileDropdown.klarway') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list two-line class="pa-0">
        <v-list-item @click="logout()" class="px-6 pb-2">
          <v-list-item-icon class="my-auto pl-1">
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >{{ $t('components.appBar.profileDropdown.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-list>
  </v-sheet>
</template>

<style scoped lang="sass">
.profileDropdown
    position: absolute
    top: 64px
    right: 0
    width: 320px
    box-shadow: 0 16px 24px rgba(0,0,0,0.20)
</style>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ProfileDropdown',
  props: {
    profileDropdown: Object
  },
  methods: {
    onClose () {
      this.profileDropdown.open = false
    },
    getAvatar () {
      return this.getNestedProperty('userData.userData.avatar', this.$store.state);
    },
    logout () {
      this.$auth.logout();
    },
    getNestedProperty (path, object) {
      path = path.split('.');
      return path.reduce((obj, key) => (obj && obj[key]) ? obj[key] : null, object);
    }
    
  },
  computed: {
    getFullName () {
      const data = this['commons/getUserData']
      return `${data.name} ${data.lastname}`
    },
    showKlarwayRegisterButton () {
      return !this['commons/isUserAdmin']
    },

    ...mapGetters([
      'commons/getUserData',
      'commons/getStudentData',
      'commons/getStudentId',
      'commons/isUserAdmin'
    ])
  }
}
</script>
