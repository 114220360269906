<template>
  <div class="loginFormCard fullHeightViewport">
      <div class="py-6 d-flex flex-column">
        <div class="d-flex justify-start">
          <v-img max-height="50px" max-width="150px" class="loginFormCardLogo" contain :src="logoPath"></v-img>
        </div>
        <div class="divAuthenticator text-center">
          <amplify-authenticator>
            <amplify-sign-in slot="sign-in" :formFields.prop="formFields">
              <div slot="secondary-footer-content"></div>
              <div slot="federated-buttons"></div>
            </amplify-sign-in>
          </amplify-authenticator>
 
        </div>
      </div>
    </div>
</template>
  
<script>

import { Auth } from 'aws-amplify';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import Configuration from '@/constants/configuration';
import { createUrl } from '../../../../core/helpers/uri-factory';

export default {
  name: 'AmplifyForm',
  props: {
    loading: Boolean,
    errorAuth: Boolean,
    serverError: Boolean,
    logoPath: String,
    data: Object
  },
  async created() {
      this.unsuscribeAuth = onAuthUIStateChange((authState, authData) => {

      this.authState = authState;
      this.user = authData;
    })
    },
  data() {
    return {
      authState: Auth.authState,
      unsuscribeAuth: undefined,
      user: Auth.currentAuthenticatedUser(),
      accessToken: undefined,
      refreshToken: undefined,
      formFields: [
        {
          type: 'username',
          value: this.data.email,
          inputProps: { required: true, autocomplete: 'username' },
        },
        {
          type: 'password',
          value: this.data.pass,
          inputProps: { required: true, autocomplete: 'password' }
        }
      ]
    }
  },
  methods: {
    validateCognito(user, password, advisor, accessToken, refreshToken) {
      const uri = createUrl('oauth');
      const url = Configuration.value('env') == 'production' ? (window.location.host == 'examenesklarway.teclab.edu.ar' ? 'examenes.teclab.edu.ar' : window.location.host) : 'localhost:8080'
      const cognitoUrl = Configuration.value('cognitoRedirectUrl')
      return new Promise((resolve, reject) => {
        fetch(uri, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0',
          },
          body: `grant_type=password&username=${user}&password=${encodeURIComponent(password)}&url=${url}&advisor=${advisor}&accessTokenCognito=${accessToken}&refreshTokenCognito=${refreshToken}&cognitoUrl=${cognitoUrl}`,
        }).then(res => { return res.json() })
          .then(async data => {
            if (!data.error) {
              this.$auth.setStorage(data);
              resolve(data);
              setTimeout(() => { this.$auth.checkTokenExpired() }, 3 * 60 * 1000)
            } else {
              reject(data.error)
            }
          }).catch(reject)
      });
    },
  },
  beforeDestroy() {
    this.unsuscribeAuth();
  },

  watch: {
    email() {
      this.autofilled = true
    },
    user() {
      Auth.currentSession().then(res => {
        const accessToken = res.getAccessToken()
        this.accessToken = accessToken.getJwtToken()
        const refreshToken = res.getRefreshToken()
        this.refreshToken = refreshToken.getToken()
      })
    },
    async refreshToken() {
      await this.validateCognito('user', 'password', this.data.advisor, this.accessToken, this.refreshToken);
      this.$auth.validateTokenCognito()
      this.$router.push('/')
    }
  },
}
</script>

<style>
:root {
  --amplify-primary-color: #106ca7;
}

.loginFormCardLogo {
  position: relative;
  top: 3em;
  left: 0;
}
.divAuthenticator {
  margin-left: 20px
}
</style>
  