<template>
  <v-navigation-drawer
    v-model="navigationDrawerApps.open"
    absolute
    right
    class="fill-height pa-4 navigationDrawer"
    temporary
  >
    <div class="d-flex justify-space-between pr-2">
      <v-btn icon @click.stop="navigationDrawerApps.open = !navigationDrawerApps.open">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <div class="headline">{{ navigationDrawerApps.title }}</div>
    </div>

    <v-container fluid class="pa-2 mt-2">
      <v-row dense>
        <v-col sm="6" v-for="item in navigationDrawerApps.items" :key="item.title">
          <v-card outlined class="text-center navigationDrawer__app">
            <v-card-text>
              <v-avatar dark color="grey lighten-3" size="48">
                <v-icon>{{item.imagen}}</v-icon>
                <!--<span class="white&#45;&#45;text headline"></span>-->
              </v-avatar>
              <div class="caption font-weight-bold pt-2">{{ item.title }}</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<style scoped lang="sass">
@import '@/assets/sass/main.scss'

.navigationDrawer
    border-radius: 0 !important
    height: 100vh !important
    width: 304px !important
    &__app
        &:hover
            box-shadow: 0 3px 12px rgba(map_get($color-primary, 'base'), 0.2)
</style>

<script>
export default {
  name: 'NavigationDrawer',
  props: {
    navigationDrawerApps: Object
  }
}
</script>
