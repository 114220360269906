import DashboardRoute from './Dashboard/Routes'
import ExamsAvailablesRoute from './ExamsAvailables/Routes'
import ExamsRoute from './Exams/Routes'

const ModulesRouter = [
    DashboardRoute,
    ExamsAvailablesRoute,
    ExamsRoute,
];

export default ModulesRouter;