export default {
  icons: [
    {
      name: 'benefits-icon',
      svg(color = 'ffffff') { return `<svg xmlns="http://www.w3.org/2000/svg" width="24.739" height="18.764" viewBox="0 0 24.739 18.764"><g id="benefits-icon" transform="translate(-1413 -966)"><path id="Trazado_2198" data-name="Trazado 2198" d="M656.607,372.265c0-.115.013-.225.022-.337L651,375l-7-3.82v4l7,3.82,5.489-3-.1-1.919h.631A4.065,4.065,0,0,1,656.607,372.265Z" transform="translate(773 605)" fill="#${color}"/><path id="Trazado_2199" data-name="Trazado 2199" d="M658.753,366.727H661.5L651,361l-11,6v8h2v-6.91l9,4.91,7.175-3.914a4.87,4.87,0,0,1,.578-.4Z" transform="translate(773 605)" fill="#${color}"/><path id="Trazado_2200" data-name="Trazado 2200" d="M661.857,373.571c-1.392-.362-1.84-.736-1.84-1.319,0-.668.619-1.134,1.656-1.134,1.091,0,1.5.521,1.533,1.288h1.355a2.445,2.445,0,0,0-1.968-2.337v-1.343h-1.84v1.325a2.393,2.393,0,0,0-2.146,2.214c0,1.416,1.171,2.121,2.882,2.532,1.533.368,1.839.908,1.839,1.478,0,.423-.3,1.1-1.655,1.1-1.263,0-1.76-.564-1.828-1.288H658.5a2.571,2.571,0,0,0,2.257,2.349v1.33h1.84v-1.318c1.2-.227,2.146-.92,2.146-2.177C664.739,374.527,663.249,373.933,661.857,373.571Z" transform="translate(773 605)" fill="#${color}"/></g></svg>` }
    },
    {
      name: 'communities-icon',
      svg(color = 'ffffff') { return `<svg xmlns="http://www.w3.org/2000/svg" width="13.972" height="20.752" viewBox="0 0 13.972 20.752"><g id="communities-icon" transform="translate(-1418 -1058.248)"><circle id="Elipse_12" data-name="Elipse 12" cx="2" cy="2" r="2" transform="translate(1423 1060)" fill="#${color}"/><path id="Trazado_2190" data-name="Trazado 2190" d="M569.253,371.877a2.877,2.877,0,1,0-5.753,0v6.575h1.644v4.726h2.466v-4.726h1.644Z" transform="translate(858.609 695.823)" fill="#${color}"/><path id="Trazado_2191" data-name="Trazado 2191" d="M546.377,364.726a4.111,4.111,0,0,1,1.989-3.519,3.1,3.1,0,0,1-.638-.87,2.876,2.876,0,0,0-4.227,2.539v6.575h1.644v4.726h2.466v-1.644h-1.233Z" transform="translate(874.5 702.973)" fill="#${color}"/><path id="Trazado_2192" data-name="Trazado 2192" d="M550.349,340.7a1.839,1.839,0,0,0,1.045-.324,3.069,3.069,0,0,1,.79-1.748,1.849,1.849,0,1,0-1.834,2.072Z" transform="translate(870.527 721.248)" fill="#${color}"/><path id="Trazado_2193" data-name="Trazado 2193" d="M590.406,340.7a1.849,1.849,0,1,0-1.834-2.072,3.069,3.069,0,0,1,.79,1.748A1.839,1.839,0,0,0,590.406,340.7Z" transform="translate(838.689 721.248)" fill="#${color}"/><path id="Trazado_2194" data-name="Trazado 2194" d="M589.811,360a2.862,2.862,0,0,0-1.351.337,3.1,3.1,0,0,1-.638.87,4.111,4.111,0,0,1,1.989,3.519v7.808h-1.233v1.644h2.466v-4.726h1.644v-6.575A2.877,2.877,0,0,0,589.811,360Z" transform="translate(839.285 702.973)" fill="#${color}"/></g></svg>` }
    },
    {
      name: 'debt-icon',
      svg(color = 'ffffff') { return `<svg xmlns="http://www.w3.org/2000/svg" width="19.162" height="19.182" viewBox="0 0 19.162 19.182"><path id="debt" d="M11,6h3l3.29-3.3a1,1,0,0,1,1.42,0l2.58,2.59a1,1,0,0,1,0,1.41L19,9H11v2a1,1,0,0,1-2,0V8a2,2,0,0,1,2-2M5,11v4L2.71,17.29a1,1,0,0,0,0,1.41l2.58,2.59a1,1,0,0,0,1.42,0L11,17h4a1,1,0,0,0,1-1V15h1a1,1,0,0,0,1-1V13h1a1,1,0,0,0,1-1V11H13v1a2,2,0,0,1-2,2H9a2,2,0,0,1-2-2V9Z" transform="translate(-2.419 -2.404)" fill="#${color}"/></svg>` }
    },
    {
      name: 'discounts-icon',
      svg(color = 'ffffff') { return `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g id="discounts-icon" transform="translate(-1416 -1102)"><path id="ic_exit_to_app_24px" d="M10.09,15.59,11.5,17l5-5-5-5L10.09,8.41,12.67,11H3v2h9.67ZM19,3H5A2,2,0,0,0,3,5V9H5V5H19V19H5V15H3v4a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Z" transform="translate(1437 1099) rotate(90)" fill="#${color}"/></g></svg>` }
    },
    {
      name: 'periods-icon',
      svg(color = 'ffffff') { return `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20"><path id="periods" d="M19,19V8H5V19H19M16,1h2V3h1a2,2,0,0,1,2,2V19a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A1.993,1.993,0,0,1,5,3H6V1H8V3h8V1M7,10H9v2H7V10m8,0h2v2H15V10m-4,4h2v2H11V14m4,0h2v2H15Z" transform="translate(-3 -1)" fill="#${color}"/></svg>` }
    },
    {
      name: 'prices-icon',
      svg(color = 'ffffff') { return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><g id="prices-icon" transform="translate(-1415 -1143)"><path id="ic_local_atm_24px" d="M11,17h2V16h1a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1H11V10h4V8H13V7H11V8H10A1,1,0,0,0,9,9v3a1,1,0,0,0,1,1h3v1H9v2h2ZM20,4H4A1.985,1.985,0,0,0,2.01,6L2,18a1.993,1.993,0,0,0,2,2H20a1.993,1.993,0,0,0,2-2V6A1.993,1.993,0,0,0,20,4Zm0,14H4V6H20Z" transform="translate(1413 1139)" fill="#${color}"/></g></svg>` }
    },
    {
      name: 'quarters-icon',
      svg(color = 'ffffff') { return `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16"><path id="center" d="M12,18H6V14h6m9,0V12L20,7H4L3,12v2H4v6H14V14h4v6h2V14M20,4H4V6H20Z" transform="translate(-3 -4)" fill="#${color}"/></svg>` }
    },
    {
      name: 'scholarships-icon',
      svg(color = 'ffffff') { return `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="20.931" viewBox="0 0 22 20.931"><g id="scholarships-icon" transform="translate(-1414 -1010)"><path id="Trazado_2201" data-name="Trazado 2201" d="M705.75,391.977l-2.44-2.779.34-3.681-3.61-.819-1.89-3.181-3.4,1.46-3.4-1.46-1.89,3.181-3.61.81.34,3.68-2.44,2.789,2.44,2.781-.34,3.69,3.61.819,1.89,3.181,3.4-1.471,3.4,1.461,1.89-3.18,3.61-.82-.34-3.68Zm-13.647-4.23a1.588,1.588,0,1,1-1.588,1.588A1.588,1.588,0,0,1,692.1,387.747Zm5.294,8.47a1.588,1.588,0,1,1,1.588-1.587A1.587,1.587,0,0,1,697.4,396.217Zm-6.088.265-1.059-1.059,7.941-7.941,1.059,1.06Z" transform="translate(730.25 628.483)" fill="#${color}"/></g></svg>` }
    },
    {
      name: 'zones-icon',
      svg(color = 'ffffff') { return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="zones-icon" transform="translate(-1415 -1186)"><path id="ic_public_24px" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM11,19.93A7.988,7.988,0,0,1,4,12a8.118,8.118,0,0,1,.21-1.79L9,15v1a2.006,2.006,0,0,0,2,2Zm6.9-2.54A1.984,1.984,0,0,0,16,16H15V13a1,1,0,0,0-1-1H8V10h2a1,1,0,0,0,1-1V7h2a2.006,2.006,0,0,0,2-2V4.59a7.982,7.982,0,0,1,2.9,12.8Z" transform="translate(1413 1184)" fill="#${color}"/></g></svg>` }
    },
    {
      name: 'notif-icon',
      svg() { return `<svg xmlns="http://www.w3.org/2000/svg" width="18.438" height="21" viewBox="0 0 18.438 21"><g id="Grupo_169" data-name="Grupo 169" transform="translate(-1409 -1830)"><path id="bell-check" d="M3,20V19l2-2V11a7,7,0,0,1,5-6.71V4a2,2,0,0,1,4,0v.29A7,7,0,0,1,19,11v1.08L18,12a6.008,6.008,0,0,0-5.66,8H3m9,3a2,2,0,0,1-2-2h2.8a6.306,6.306,0,0,0,.85,1.13A1.979,1.979,0,0,1,12,23Z" transform="translate(1406 1828)" fill="#7e7e7e"/><path id="settings_1_" data-name="settings (1)" d="M5.7,6.756A1.233,1.233,0,1,1,6.931,5.523,1.233,1.233,0,0,1,5.7,6.756m2.618-.891a2.737,2.737,0,0,0,.025-.342,3.012,3.012,0,0,0-.025-.352L9.059,4.6A.178.178,0,0,0,9.1,4.371L8.4,3.152a.173.173,0,0,0-.215-.078L7.3,3.427a2.554,2.554,0,0,0-.6-.345l-.13-.934A.178.178,0,0,0,6.4,2H4.994a.178.178,0,0,0-.176.148l-.13.934a2.554,2.554,0,0,0-.6.345l-.877-.352A.173.173,0,0,0,3,3.152L2.3,4.371a.174.174,0,0,0,.042.225l.743.574a3.013,3.013,0,0,0-.025.352,2.737,2.737,0,0,0,.025.342l-.743.585a.174.174,0,0,0-.042.225L3,7.894a.177.177,0,0,0,.215.078l.877-.356a2.447,2.447,0,0,0,.6.349l.13.934a.178.178,0,0,0,.176.148H6.4A.178.178,0,0,0,6.579,8.9l.13-.934a2.565,2.565,0,0,0,.6-.349l.877.356A.177.177,0,0,0,8.4,7.894l.7-1.219a.178.178,0,0,0-.042-.225Z" transform="translate(1418.313 1840.6)" fill="#7e7e7e"/></g></svg>`}
    }
  ]
}
