import { i18n } from '../plugins/i18n';
import store from '../../store'

export function fullfillMiddleware(res) {
  return res;
}

export function rejectMiddleware(error) {
  const appError = {
    title: '',
    message: '',
    status: 0,
    code: '',
    codeMeaning: ''
  };

  const lang = store.getters['settings/getLanguageLocate'] ? store.getters['settings/getLanguageLocate'] : 'es'

  appError.code = error.data?.errorDescription ?? error.status;
  appError.codeMeaning = i18n.messages[lang]?.responseCodeMeaning[error.data?.errorDescription]
    || i18n.messages[lang]?.responseCodeMeaning.default;

  appError.status = error.data?.errorCode ?? error.status;

  appError.message = error?.data ?? error;

  return Promise.reject(appError);
}

export default [
  fullfillMiddleware,
  rejectMiddleware
];
