<template>
    <SignInForm
        @logIn="logIn"
        :loading="loading"
        :errorAuth="errorAuth"
        :serverError="serverError"
    ></SignInForm>
</template>

<script>
    import redirects from '@/constants/redirects';
    import SignInForm from '../Components/SignInForm/SignInForm';
    import api from '@/api';

    const { $core } = api;
    const { $users } = $core;

    export default {
        components: {
            SignInForm
        },
        data () {
            return {
                loading: false,
                errorAuth: false,
                serverError: false
            }
        },
        methods: {
            async logIn(data) {
                this.errorAuth = false;
                this.serverError = false;
                this.loading = true;
                const isCognitoUser = await this.checkUserType(data.email);
                if (isCognitoUser?.isCognitoUserTest) {
                    localStorage.setItem('is_cognito_user', true);
                    this.$router.push({ name: 'LoginAmplifyForm', params: { data } }).catch(err => { throw err; });
                } else {
                    this.$auth.login(data.email, data.pass, data.advisor)
                        .then(() => {
                            this.loading = false;
                            if (location.hash != '') {
                                window.localStorage.setItem('hash', location.hash.replace('#', ''));
                                this.$store.dispatch('commons/initHash', location.hash.replace('#', ''));
                            } else {
                                this.$store.dispatch('commons/initHash', window.localStorage.getItem('hash'));
                            }

                            if (!this.$route.query.redirect) {
                                this.$router.push(redirects.URL_LOGIN_REDIRECT)
                            } else {
                                this.$router.push(this.$route.query.redirect);
                            }

                        }).catch((err) => {
                            if (err === 'invalid_grant') this.errorAuth = true;
                            else this.serverError = true;
                            this.loading = false;
                        })
                }
            },
            async checkUserType(username) {
                try {
                    const userType = await $users.checkUserType(username, { authorization: true });
                    return userType.data;
                } catch (error) {
                    this.serverError = true;
                    this.loading = false;
                }
            }
        }
    }
</script>
