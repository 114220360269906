<template>
  <div>
    <template v-if="$v.userCode.text.$anyError && (this.userCode.text.length == 6)">
      <v-alert
        text
        dense
        color="error"
        :value="!this.$v.userCode.text.valid && !this.$v.userCode.text.$pending "
      >{{ $t('modules.login.securityCode.password.form.validations.codeInvalid') }}</v-alert>
    </template>
    <v-text-field
      v-model="userCode.text"
      :success="!$v.userCode.text.$invalid"
      :loading="loading"
      :label="$t('modules.login.securityCode.user.form.labels.code')"
      :error="$v.userCode.text.$anyError && (this.userCode.text.length == 6)"
      outlined
      required
      maxlength="6"
      type="text"
      @input="$v.userCode.text.$touch()"
      @blur="$v.userCode.text.$touch()"
    ></v-text-field>
  </div>
</template>

<script>
import { minLength } from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'
export default {
  name: 'SecurityCodeUserForm',
  props: {
    userCode: Object
  },
  data () {    return {
      loading: false,
    }  },
  validations: {
    userCode: {
      text: {
        minLength: minLength(6),
        valid (value) {
          return new Promise((resolve) => {
            if (value.length !== 6) resolve(false);
            else {
              const {cellphone} = this['login/getRecoveryData'];
              this.loading = true;
              this.$auth.validateCodeForEmail(cellphone, value)
                .then((data) => {
                  this.$store.dispatch('login/setRecoveryInfo', data).then(() => {
                    this.$emit('userCodeSuccess');
                    this.loading = false;
                    resolve(true)
                  })
                })
                .catch(() => {
                  this.loading = false;
                  resolve(false)
                })
            }
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters(['login/getRecoveryData']),
    codeErrors () {
      const errors = [];
      if(!this.$v.userCode.text.$anyError && this.userCode.text.length < 6  ) return errors;
      if (this.$v.userCode.text.$invalid) errors.push(this.$t('modules.login.securityCode.user.form.validations.codeInvalid'));
      return errors;
    }
  },
  watch: {
    '$v.userCode.text.minLength': function (value) {
      this.userCode.valid = value && this.$v.userCode.text.valid;
    }
  }
};
</script>
