const notFoundStateTranslations = {
    es: {
        title: '¡Upss!',
        subtitle: 'Página no encontrada',
        description: 'Lo sentimos, pero lo que intentas buscar no está disponible.',
    },
    en: {
        title: 'Upss!',
        subtitle: 'Content not found',
        description: 'We are sorry, what you are trying to find is not available.',
    }
};

export default notFoundStateTranslations
