import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('klarway');

export class User extends Api {
    constructor(http, config = {}) {
        super(http, url, 'user', config);
    }

    async consultRegistry(user, key) {
        const uri = this._uriConstructor(null, 'consultRegistry');
        return this._http.get(`${uri}?user=${user}&key=${key}`, this._config);
    }

    async saveUser(instance, config = {}) {
        config = {...this._config, ...config};
        const uri = this._uriConstructor(null, 'registry-user');
        return this._http.post(uri, instance, config);
    }

    async launchFacialRecognition(user, key, exam) {
        const uri = this._uriConstructor(null, 'facialRecognition');
        const config = {...this._config, timeout: 600000};
        return this._http.get(`${uri}?user=${user}&key=${key}&exam=${exam}`, config);
    }

    async launchActivatePadron(user, key, instance = {}, config = {}) {
        config = {...this._config, ...config};
        const uri = this._uriConstructor(null, 'activate-user');
        return this._http.post(`${uri}?user_id=${user}&key=${key}`, instance, config);
    }

}