<template>
  <v-btn
    :class="['px-6', (light || white) ? 'grey--text text--darken-2' : '']"
    @click="click()"
    rounded
    :outlined="outlined"
    :disabled="disabled"
    :loading="loading"
    :depressed="depress"
    :color="buttonState ? buttonState : color"
    :x-small="size === 'x-sm'"
    :small="size === 'sm'"
    :large="size === 'lg'"
    :x-large="size === 'x-lg'"
  >
    <v-icon :left="!!buttonState || !!icon ">{{ buttonState ? icons[buttonState] : icon }}</v-icon>

    {{ buttonState ? this[texts[buttonState]] : text}}
  </v-btn>
</template>
<script>

export default {
  props: {
    success: Boolean,
    loading: { type: Boolean, default: false },
    error: Boolean,
    outlined: Boolean,
    secondary: Boolean,
    white: Boolean,
    grey: Boolean,
    light: Boolean,
    depressed: { type: Boolean, default: false },
    disabled: Boolean,

    size: { type: String, default: 'lg' },
    text: String,
    icon: String,
    successText: String,
    errorText: String,
  },
  data () {
    return {
      buttonState: null,
      buttonLoading: null,
      icons: {
        error: 'mdi-alert-circle',
        success: 'mdi-check'
      },
      texts: {
        error: 'errorText',
        success: 'successText'
      },
      localColor: '',
    }
  },
  methods: {
    click () {
      this.$emit('clicked');
    },
  },
  watch: {
    success (val) {
      if (val) {
        setTimeout(() => {
          this.buttonLoading = false;
          this.buttonState = 'success';
          setTimeout(() => {
            this.buttonState = null;
            this.$emit('end');
          }, 1500)
        }, 1000)
      }
    },
    error (val) {
      if (val) {
        setTimeout(() => {
          this.buttonLoading = false;
          this.buttonState = 'error';
          setTimeout(() => {
            this.buttonState = null;
            this.$emit('end');
          }, 3000)
        }, 1000)
      }
    },
    loading () {
      if (this.loading) {
        this.buttonLoading = this.loading;
      }
    }
  },
  computed: {
    color () {
      if (this.white) {
        return 'white';
      } else if (this.grey) {
        return 'grey lighten-2';
      } else if (this.secondary) {
        return 'secondary';
      } else if (this.light) {
        return 'primary lighten-1';
      } else {
        return 'primary';
      }
    },
    depress () {
      if (this.color === 'grey lighten-2' || this.color === 'white') {
        return true;
      } else {
        return this.depressed;
      }
    }
  },
  created () {
    this.buttonLoading = this.loading
  }
}
</script>

<style scoped lang="sass">
.v-btn
    transition: all 400ms ease

</style>
