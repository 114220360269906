<template>
  <v-container fluid class="d-flex pa-0 white fullHeightViewport">
    <!-- <NavigationDrawer v-if="$route.name !== 'exams'"></NavigationDrawer> -->
    <NavigationDrawer v-if="!showFullScreen"></NavigationDrawer>
    <v-container fluid class="d-flex flex-column pa-0 ma-0">
      <AppBar v-if="!showFullScreen"></AppBar>
      <v-content class="scrollableContent">
        <router-view v-if="initDataLoaded"></router-view>
      </v-content>
    </v-container>
    <AlertPopup></AlertPopup>
  </v-container>
</template>

<script>
import NavigationDrawer from '@/components/NavigationDrawer/NavigationDrawer.vue'
import AppBar from '@/components/AppBar/AppBar.vue'
import AlertPopup from '@/components/AlertPopup/AlertPopup';

export default {
  components: {
    AlertPopup,
    NavigationDrawer,
    AppBar,
  },
  data () {
    return {
      initDataLoaded: false
    }
  },
  computed: {
    showFullScreen() {
      if (this.$route.fullPath.includes('/exam/')) return true
      else return false
    },
  },
  async mounted () {
    try {
      await this.$store.dispatch('commons/initData')
    } finally {
      this.initDataLoaded = true
    }
  },
};
</script>
