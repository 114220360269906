<template >
<div class="loginFormCard fullHeightViewport">
  <div class="py-6 d-flex flex-column">
    <div class="d-flex justify-start">
      <v-img max-width="100px" height="auto" class="loginFormCard__logo" contain :src="logoPath"></v-img>
    </div>
    <div>
      <h3 class="display-1 primary--text">{{ securityCodeData.title }}</h3>
      <p class="body-2 mb-0">{{ securityCodeData.description }}</p>
      <div class="d-flex flex-column pt-4">
        <div class="d-flex pb-2" v-for="(item, i) in securityCodeData.inputs" :key="i">
          <v-icon class="mr-3 ">{{ item.icon }}</v-icon> <span class="body-1">{{ item.text }}</span>
        </div>
      </div>
      <div class="d-flex justify-end">
        <span class="primary--text pb-2 cursor-pointer"
          @click="resendCode">{{ $t('modules.login.securityCode.resendCode') }}</span>
      </div>
      <v-divider></v-divider>
    </div>
  </div>
  <form>
    <h5 class="title primary--text text--lighten-5 mb-4">
      {{ securityCodeData.formTitle }}
    </h5>
    <slot></slot>
    <div class="d-flex justify-space-between pt-3">
      <v-btn @click="goBack" class="px-6" text rounded large>Atrás</v-btn>
      <!-- <v-btn @click="validateCode" :disabled="!canGoNextStep" color="primary" class="px-6" :loading="loading" rounded large>Siguiente</v-btn> -->
    </div>
  </form>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SecurityCodeForm',
  props: {
    loading: Boolean,
    securityCodeData: Object,
    canGoNextStep: Boolean
  },
  computed: {
    ...mapGetters({
      logoPath: 'commons/getLogoImageUrl',
    }),
  },
  methods: {
    resendCode () {
      this.$emit('resendCode')
    },
    validateCode () {
      this.$emit('validateCode')
    },
    goBack () {
      this.$router.go(-1)
    }
  }
};
</script>
