import camelCase from 'lodash.camelcase';
import decamelize from 'decamelize';
import baseUrl from '../constants/uri';

const files = require.context('../api', true, /\.js$/);
const microservices = [
  'oauth/token',
];

/**
 * This section of the module creates all microservices dinamically
 * it use the name of the folder inside of api directory for creates
 * every microservice, in other words the name of every folther inside
 * api directory is the name of every microservice.
 */
files.keys().forEach((fileName) => {
  if (fileName !== './index.js' && /(\/index.js$)/.test(fileName)) {
    let [, microservice,] = fileName.split('/');
    microservice = camelCase(microservice); // TODO: (Diego) if microservice is not required in camelCase remove this line
    if (!microservices.includes(microservice)) {
      microservices.push(microservice);
    }
  }
});

/**
 * @param  {string} microservice
 * @return {string}
 *
 * This function creates the uri
 * for every microservice
 */
export function createUrl (microservice = '') {
  if (typeof microservice === 'string' && microservice.trim()) {
    const regexp = new RegExp(camelCase(microservice), 'g');
    const selected = microservices.filter((element) => regexp.test(element));
    const selectedMicroservice = selected.length ? decamelize(selected[0], '-') : 'not-found';
    return `${baseUrl}/${selectedMicroservice}`;
  }

  return baseUrl;
}
