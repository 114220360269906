<template>
    <v-dialog v-model="active" width="500">
      <div class="alertPopup">
        <v-card class="pa-10 d-flex flex-column justify-center align-center alertPopup__content">
          <div class="pb-5 alertPopup__icon">
            <template v-if="icon">
              <div :class="['circle', 'color-' + icon.color, 'text-center', 'mx-auto']">
                <v-icon size="60" color="white" :class="['icon', 'color-' + icon.color]">{{ icon.name }}</v-icon>
              </div>
            </template>
          </div>
          <v-card-title
            :class="['headline', 'message-title', color + '--text', 'text-center', 'd-block']"
            style="word-break: break-word;">
            {{ title }}
          </v-card-title>
          <v-card-text v-if="Array.isArray(content) && content.length" class="text-center">
            <template v-for="(item, i) in content">
              <p class="title mb-0" v-if="i<3" :key="'item'+i">{{ item.value }}</p>
              <p class="subtitle-2 mb-0 pt-1" v-if="i === 3" :key="'item' + i">{{ hasMoreItems(content, i) }}</p>
            </template>
          </v-card-text>
          <v-card-text v-else class="text-center">
            <p class="mb-0 message-content">{{ content }}</p>
          </v-card-text>
          <v-card-actions class="d-flex justify-space-around mt-4">
            <v-btn
              v-if="actionSecondary"
              rounded
              large
              text
              @click="()=>cancel(actionSecondary.callback)"
              class="px-6 mx-4"
            >{{ actionSecondary.text }}</v-btn>
            <v-btn
              v-if="actionPrimary"
              rounded
              :color="color"
              class="px-6 mx-4"
              large
              dark
              @click="()=>confirm(actionPrimary.callback)"
            >{{ actionPrimary.text }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AlertPopup',
  data () {
    return {}
  },
  computed: {
    active: {
      get () {
        return this.$store.state.commons.openPopUp;
      },
      set (value) {
        this.$store.dispatch('commons/togglePopUp', value);
      }
    },
    title () {
      return this.$store.state.commons.alertPopUp?.title
    },
    actionPrimary () {
      return this.$store.getters['commons/getProp']('alertPopUp.actionPrimary', this.$store.state.commons)
    },
    actionSecondary () {
      return this.$store.getters['commons/getProp']('alertPopUp.actionSecondary', this.$store.state.commons)
    },
    icon () {
      return this.$store.getters['commons/getProp']('alertPopUp.icon', this.$store.state.commons)
    },
    color () {
      return this.$store.getters['commons/getProp']('alertPopUp.color', this.$store.state.commons)
    },
    content () {
      return this.$store.getters['commons/getProp']('alertPopUp.content', this.$store.state.commons)
    },
  },
  methods: {
    ...mapActions(['commons/closePopUp']),
    cancel (callback) {
      if (typeof callback === 'function') {
        callback();
      }

      this['commons/closePopUp']();
    },
    confirm (callback) {
      if (typeof callback === 'function') {
        callback();
      }

      this['commons/closePopUp']();
    },
    hasMoreItems (content, i) {
      return `${content.length - i} ${((content.length - i) < 2)
        ? this.$t('components.alertPopup.moreItemsSingular')
        : this.$t('components.alertPopup.moreItemsPlural')}`
    }
  },
}
</script>

<style lang="sass" scoped >
@import '@/assets/sass/main'

.alertPopup
  width: 100%

  .message-title,
  .message-content
    text-transform: lowercase
    word-wrap: normal

  .message-title::first-letter,
  .message-content::first-letter
    text-transform: uppercase

  &__content
    min-width: 30vw
    max-width: 40vw
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 20px 50px rgba(0, 0, 0, 0.2)
  &__icon
    width: fit-content

  .circle
    border-radius: 50% !important

  .icon
    margin: 35px
    padding: 15px
    position: relative
    border-radius: 50%

  @mixin syntax-colors($error: '', $success: '', $warning: '', $info: '')
    .circle.color-error
      background-color: var(--v-#{$error}-lighten5)
    .icon.color-error
      background-color: var(--v-#{$error}-base)

    .circle.color-success
      background-color: var(--v-#{$success}-lighten5)
    .icon.color-success
      background-color: var(--v-#{$success}-base)

    .circle.color-warning
      background-color: var(--v-#{$warning}-lighten5)
    .icon.color-warning
      background-color: var(--v-#{$warning}-base)

    .circle.color-info
      background-color: var(--v-#{$info}-lighten5)
    .icon.color-info
      background-color: var(--v-#{$info}-base)

  @include syntax-colors($error: 'error', $success: 'success', $warning: 'warning', $info: 'info')

</style>
