/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import Configuration from './constants/configuration';

const awsmobile = {
    "aws_project_region": Configuration.value('cognitoRegion'),
    "aws_cognito_region": Configuration.value('cognitoRegion'),
    "aws_user_pools_id": Configuration.value('cognitoPoolId'),
    "aws_user_pools_web_client_id": Configuration.value('cognitoClientId')
};


export default awsmobile;
