const dashboardTranslations = {
    es: {
        welcome_message: {
            title: '¡Hola ',
            description: 'Para realizar alguna gestión dirígete al menú lateral. Ahí encontrarás todas las opciones disponibles.'
        },
    },
    en: {
        welcome_message: {
            title: 'Hi ',
            description: 'Find all the options available into the side menu.'
        },
    }
};

export default dashboardTranslations
