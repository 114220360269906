
export default {
  es: {
    search: 'Buscar',
    select: 'Seleccionar todos',
    empty_list: 'Lista vacia',
    no_records: 'No hay elementos para mostrar',
    selecteds: 'selecionados',
  },
  en: {
    search: 'Search',
    select: 'Select all',
    empty_list: 'Empty list',
    no_records: 'No matching records found',
    selecteds: 'Selected items: ',
  }
};
