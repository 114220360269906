import dashboardTranslations from './Dashboard/Translations/index'
import examsAvailablesTranslations from './ExamsAvailables/Translations/index'
import examsTranslations from './Exams/Translations/index'
import loginTranslations from './Login/Translations/index'


const es_CL = {
    login: { ...loginTranslations.es_CL },
}
const es_MX = {
    examsAvailables: { ...examsAvailablesTranslations.es_MX},
    login: { ...loginTranslations.es_MX },
};
const es = {
    examsAvailables: { ...examsAvailablesTranslations.es },
    exams: { ...examsTranslations.es },
    home: { ...dashboardTranslations.es },
    login: { ...loginTranslations.es },
};

const en = {
    examsAvailables: { ...examsAvailablesTranslations.en },
    exams: { ...examsTranslations.en },
    home: { ...dashboardTranslations.en },
    login: { ...loginTranslations.en },
};

export const modulesTranslations = { es, en, es_CL, es_MX }
