import Exams from '../Exams.vue'
import ExamsAssestment from '../Views/Assestment.vue';
import ExamsAssestmentReview from '../Views/AssestmentReview.vue';

const ExamsRoute = {
    path: '/exam',
    meta: {
        name: {
            es: 'Exámenes',
            en: 'Exams',
        }
    },
    component: Exams,
    children: [
        {
            path: ':id',
            component: ExamsAssestment,
            meta: { name: 'Assestment', path: 'available_exams', permission: 'CREATE' }
        },{
            path: ':id/review',
            component: ExamsAssestmentReview,
            meta: { name: 'Review', path: 'available_exams', permission: 'UPDATE' }
        },
    ]
};

export default ExamsRoute