import { Api } from '../api';
import baseUrl from '@/constants/uri';

export class Sumadi extends Api {
    constructor(http, config = {}) {
        super(http, `${baseUrl}/sumadi`, null, config);
    }

    async launchStudent(id) {
        const uri = this._uriConstructor(null, 'launch-student');
        return this._http.post(`${uri}?exam_id=${id}`, this._config);
    }
}
