// To use the store of an application you must do the following --> "import *app* from '../*app*/store'"
//                                                              -->  add *app* in the object 'modules'


// Import modules stores

import commons from './commons'
import login from '#/Login/Store'

// Define object

const modules = {
  commons,
  login,
};

export default modules
