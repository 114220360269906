<template>
  <v-sheet class="appBar">
    <v-app-bar elevation="0" class="appBar__component">
      <!-- <img v-if="isTeclab" width="60" height="30" src="https://portalalumno.teclab.edu.ar/images/logo.svg" /> -->
      <img  width="60" height="30" :src="logoPath" />
      <!-- <img width="60" :src="this.$store.state.configurations.logoPath" />-->
      <div class="flex-grow-1"></div>

      <!-- <v-btn icon class="mr-2">
        <v-icon>mdi-bell</v-icon>
      </v-btn> -->

      <div class="searchStudentInput mx-5" v-if="isAdmin">
        <v-text-field
          class="ma-1"
          v-model="search"
          placeholder="Buscar estudiante..."
          prepend-inner-icon="mdi-magnify"
          rounded
          outlined
          dense
          hide-details
          clearable
          @click:clear="clearStudent"
          @keyup.enter="getStudent"
        ></v-text-field>
      </div>

      <v-btn v-if="$permissions.applications.STATUS && $permissions.applications.READ" icon @click.stop="navigationDrawerApps.open = !navigationDrawerApps.open" class="mr-4">
        <v-icon>mdi-apps</v-icon>
      </v-btn>

      <v-avatar
        color="blue"
        size="32"
        class="cursor-pointer"
        @click.stop="profileDropdown.open = !profileDropdown.open"
      >
        <img :src="_self['commons/getUserData']['avatar']" />
      </v-avatar>
    </v-app-bar>

    <v-progress-linear absolute color="primary" indeterminate v-if="loading.status && !loading.drawer"></v-progress-linear>
    <NavigationDrawerApps v-if="$permissions.applications.STATUS && $permissions.applications.READ" :navigationDrawerApps="navigationDrawerApps"></NavigationDrawerApps>
    <ProfileDropdown :profileDropdown="profileDropdown"></ProfileDropdown>
  </v-sheet>
</template>

<style lang="sass">
.appBar
    position: relative
    z-index: 5
    .v-overlay
        .v-overlay__scrim
            background: transparent !important
    &__component
        border-bottom: 1px solid #ddd !important

    .searchStudentInput
      max-widht: 300px
</style>

<script>
import NavigationDrawerApps from './NavigationDrawerApps'
import ProfileDropdown from './ProfileDropdown'

import { mapGetters } from 'vuex'

import Configuration from '../../constants/configuration';

export default {
  name: 'AppBar',
  components: {
    NavigationDrawerApps,
    ProfileDropdown
  },
  data () {
    return {
      search: '',
      navigationDrawerApps: {
        open: false,
        title: this.$t('components.appBar.navigationDrawerApps.title'),
        items: [
          { title: 'Administrativo', imagen: 'mdi-account-outline', },
          { title: 'Docente', imagen: 'mdi-apps', },
          { title: 'Alumno', imagen: 'mdi-school', },
        ],
      },
      profileDropdown: {
        open: false,
      }
    }
  },
  mounted() {
    const student = JSON.parse(localStorage.getItem('alumnoSeleccionado'));
    if (student) {
      this.search = student.name
      this.getStudent()
    }
  },
  methods: {
    clearStudent() {
      this.search = ''
      this.$store.dispatch('commons/setStudentData', null);
      if (this.$route.path != '/exams-available') this.$router.push('/exams-available')
    },
    getStudent() {
      if (this.isAdmin) {
        this.$store.dispatch('commons/setStudentData', this.search);
        if (this.$route.path != '/exams-available') this.$router.push('/exams-available')
      }
    },
    getAvatar () {
      return this.getNestedProperty('loggedUserData.userData.avatar', this.$store.state);
    },
    logout () {
      this.$auth.logout();
    },
    getNestedProperty (path, object) {
      path = path.split('.');
      return path.reduce((obj, key) => (obj && obj[key]) ? obj[key] : null, object);
    },
  },
  computed: {
    isTeclab() {
      return Configuration.value('theme') == 'teclab'
    },
    isAdmin() {
      return this['commons/isUserAdmin']
    },
    loading() {
      return this['commons/getLoadingState']
    },
    logoPath() {
      return this['commons/getLogoImageUrl']
    },
    ...mapGetters([
      'commons/getUserData',
      'commons/getLoadingState',
      'commons/isUserAdmin',
      'commons/getLogoImageUrl',
    ])
  }
}
</script>
