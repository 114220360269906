const loginTranslations = {
    es_CL: {
        signIn: {
            welcomeMessage: '¡Hola! Qué bueno tenerte por aquí.',
            subMessage: 'Ingresa tu RUT y contraseña para acceder.',
            items: {
                identification: 'RUT sin dígito',
                identification_placeholder: 'Tu RUT',
            },
            errors: {
                invalidEmail: 'El RUT es inválido',
                requiredEmail: 'El RUT es requerido',
            }
        }
    },
    es_MX: {
        signIn: {
            subMessage: 'Ingresa tu CURP y Contraseña',
            items: {
                identification: 'Tu CURP',
                identification_placeholder: 'Tu CURP',
            },
            errors: {
                invalidEmail: 'El CURP es inválido',
                requiredEmail: 'El CURP es requerido',
            }
        }
    },
    es: {
        signIn: {
            welcomeMessage: '¡Hola! Qué bueno tenerte por aquí.',
            subMessage: 'Ingresa tu DNI y contraseña para acceder.',
            serverError: 'Error en el servidor. Intente nuevamente más tarde.',
            authError: 'Error de autenticación. Se ha ingresado un usuario y/o contraseña incorrecto.',
            items: {
                email: 'Correo Electrónico',
                identification: 'Tu DNI',
                identification_placeholder: 'Tu DNI',
                password: 'Contraseña',
                advisor: '¿Es asesor?'
            },
            errors: {
                invalidEmail: 'El DNI es inválido',
                requiredEmail: 'El DNI es requerido',
                requiredPassword: 'La contraseña es requerida'
            }
        },
        recovery: {
            password: {
                title: 'Restablecer Contraseña',
                description: 'Ingresa tu email y te enviaremos un código que te permitira restablecer la contraseña.',
                form: {
                    labels: {
                        email: 'Correo Electrónico',
                        checksTitle: 'Recibir código por:',
                        checkSMS: 'SMS',
                        checkMail: 'Correo electrónico'
                    },
                    validations: {
                        emailRequired: 'El email es requerido!',
                        emailInvalid: 'El email es invalido!',
                        emailNonExistent: 'El correo ingresado no existe!'
                    }
                }
            },
            user: {
                title: 'Recuperar Cuenta',
                description: 'Introduce tu número de teléfono y te enviaremos un código de recuperación de la cuenta.',
                detail: 'Te enviaremos un SMS al número que nos proporciones, el cual puede tener costos adicionales según tu compañía telefónica.',
                form: {
                    labels: {
                        phone: 'Teléfono'
                    },
                    validations: {
                        phoneRequired: 'El teléfono es requerido!',
                        phoneInvalid: 'El teléfono es invalido!'
                    }
                }
            }
        },
        securityCode: {
            resendCode: 'No recibí el código',
            password: {
                title: 'Restablecer Contraseña',
                description: 'Te enviamos un código de restablecimiento a:',
                form: {
                    title: 'Ingresa el código recibido',
                    labels: {
                        code: 'Código'
                    },
                    validations: {
                        codeInvalid: 'El codigo es invalido, ingresa nuevamente o solicita un nuevo codigo!',
                        codeRequired: 'El codigo es invalido, ingresa nuevamente o solicita un nuevo codigo!',
                        codeLength: 'El codigo es invalido, ingresa nuevamente o solicita un nuevo codigo!'
                    }
                }
            },
            user: {
                title: 'Recuperar Cuenta',
                description: 'Te enviamos un código de restablecimiento a:',
                form: {
                    title: 'Ingresa el código recibido',
                    labels: {
                        code: 'Código'
                    },
                    validations: {
                        codeInvalid: 'El codigo es invalido, ingresa nuevamente o solicita un nuevo codigo!',
                        codeRequired: 'El codigo es invalido, ingresa nuevamente o solicita un nuevo codigo!',
                        codeLength: 'El codigo es invalido, ingresa nuevamente o solicita un nuevo codigo!'
                    }
                }
            }
        },
        recovered: {
            password: {
                title: 'Crear nueva Contraseña',
                changeError: 'Lo sentimos hubo un problema con el servidor! Intente nuevamente!',
                form: {
                    labels: {
                        newPass: 'Nueva contraseña',
                        repeatPass: 'Repetir contraseña',
                    },
                    validations: {
                        passRequired: 'La contraseña es requerida!',
                        passLength: 'Minimo de 8 caracteres!',
                        passEqual: 'Las contraseñas deben ser iguales!'
                    }
                },
                securityProgressBar: {
                    label: '',
                    categories: {
                        down: 'Bajo',
                        middle: 'Medio',
                        high: 'Alto'
                    }
                }
            },
            user: {
                title: 'Tu cuenta recuperada es:',
                authError: 'Error de autenticación. Se ha ingresado un  usuario y/o constraseña incorrecto.',
                switchAccount: 'Usar otra cuenta',
                form: {
                    title: 'A continuación, ingresa tu contraseña',
                    labels: {
                        password: 'Contraseña'
                    }
                }
            }
        }
    },
    en: {
        signIn: {
            welcomeMessage: 'Start session',
            subMessage: 'Enter RUT or E-mail and Password',
            serverError: 'Server error. Please try again later.',
            authError: 'Authentication error. Incorrect User or Password',
            items: {
                email: 'RUT or E-Mail',
                password: 'Password',
            },
            errors: {
                invalidEmail: 'E-mail is invalid',
                requiredEmail: 'User is required',
                requiredPassword: 'Password is required'
            }
        }
    }
};

export default loginTranslations
