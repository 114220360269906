const AutocompleteInputTranslations = {
    es: {
        isRequired: 'Ingrese una ciudad',
    },
    en: {

    }
};

export default AutocompleteInputTranslations
