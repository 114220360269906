export default {

  // common state

  userData: {},
  loggedUserData: {},
  isAdmin: false,
  alertPopUp: null,
  openPopUp: false,
  configurations: {},
  loading: {counter: 0, drawer: false},
  permissions: null,
  isKlarway: false,
  hash:''
  
}
