/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
export class Api {
  constructor(http, baseUrl, resource, config = {}) {
    this._http = http;
    this._baseUrl = baseUrl;
    this._resource = resource;

    if (Object.keys(config).length) {
      this._config = config;
    }
  }

  get http() {
    return this._http;
  }

  set http(http) {
    if (http) {
      this._http = http;
    }
  }

  get baseUrl() {
    return this._baseUrl;
  }

  set baseUrl(url) {
    if (url) {
      this._baseUrl = url;
    }
  }

  get resource() {
    return this._resource;
  }

  set resource(resource) {
    if (resource) {
      this._resource = resource;
    }
  }

  get config() {
    return this._config;
  }

  set config(config) {
    if (config) {
      this._config = config;
    }
  }

  /**
   * @param  {object} instance - resource to create
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<resource>}
   */
  async create(instance, config = {}, apiMethod = null) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(null, apiMethod);

    return this._http.post(uri, instance, config);
  }

  /**
   * @param  {string} apiMethod=null
   * @param  {object} filter={} - filter object
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<resource[]>}
   */
  async find(apiMethod = null, filter = null, config = {}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(null, apiMethod, filter);

    return this._http.get(uri, config);
  }

  /**
   * @param  {string} id
   * @param  {string} apiMethod=null
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<resource>}
   */
  async findById(id, apiMethod = null, config = {}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(id, apiMethod);

    return this._http.get(uri, config);
  }

  /**
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<{count: number}>}
   */
  async count(config = {}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(null, 'count');

    return this._http.get(uri, config);
  }

  /**
   * @param  {string} id
   * @param  {object} instance - resource to update
   * @param  {string} apiMethod=null
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<resource>}
   */
  async update(id, instance, apiMethod = null, config = {}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(id, apiMethod);

    return this._http.put(uri, instance, config);
  }

  /**
   * @param  {string} id
   * @param  {object} partial - resource to patch
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<resource>}
   */
  async updateAttributesById(id, partial, config = {}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(id);

    return this._http.patch(uri, partial, config);
  }

  /**
   * @param  {object} partial - attributes to update on resources
   * @param  {object} where - where object
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<{count: number}>}
   */
  async updateAttributesWhere(partial, where, config = {}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(null, null, where);

    return this._http.patch(uri, config);
  }

  /**
   * @param  {string} id
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<void>}
   */
  async delete(id, config = {}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(id);

    return this._http.delete(uri, config);
  }

  /**
   * @param  {Array.<{id: string|number}>} instance - resource to create
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<resource>}
   */
  async bulkDelete(instance, config = {}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(null, 'bulk', null);

    return this._http.post(uri, instance, config);
  }

  /**
   * @param  {string} id=''
   * @param  {string} apiMethod=''
   * @return {string}
   */
  _uriConstructor(id = '', apiMethod = '', filterOrQuery = null) {
    let uri = `${this._baseUrl}`
    
    if (this._resource) {
      uri += `/${this._resource}`
    }

    if (id) {
      uri += `/${id}`;
    }

    if (apiMethod) {
      uri += `/${apiMethod}`;
    }

    if (filterOrQuery) {
      uri += `?filter=${JSON.stringify(filterOrQuery)}`;
    }

    return uri;
  }

  _queryConstructor(filter) {
    let query = '';

    if (this._isObject(filter)) {
      query = '?';

      if (filter.skip || filter.offset) {
        query += `filter[skip]=${filter.skip || filter.offset}`;
      }

      if (filter.limit) {
        query += `filter[limit]=${filter.limit}`;
      }

      if (filter.order) {
        if (filter.order.length > 1) {
          filter.order.forEach((item, index) => {
            query += `filter[order][${index}]=${item}`;
          });
        } else {
          query += `filter[order]=${filter.order[0]}`;
        }
      }

      if (filter.where) {
        query += `filter={"where":${JSON.stringify(filter.where)}}`;
      }
    }

    return query;
  }

  _isObject(value) {
    return Object.prototype.toString.call(value).indexOf('Object') !== -1;
  }
}
