<template>
  <div class="loginFormCard fullHeightViewport">
    <div class="py-6 d-flex flex-column">
      <div class="d-flex justify-start">
        <v-img
          :max-width="isTeclab ? '250px' : '100px'"
          height="auto"
          class="loginFormCard__logo"
          contain
          :src="logoPath"
        ></v-img>
      </div>
      <div class="text-center mt-12">
        <h1 class="text" style="font-size: 22px;">{{ $t('modules.login.signIn.welcomeMessage') }}</h1>
        <p class="body-1 mb-0">{{ $t('modules.login.signIn.subMessage') }}</p>
      </div>
    </div>
    <v-alert text dense color="error" class="ma-0" :value="errorAuth">
      {{ $t('modules.login.signIn.authError') }}
    </v-alert>
    <v-alert text dense color="error" class="ma-0" :value="serverError">
      {{ $t('modules.login.signIn.serverError') }}
    </v-alert>
    <form @keyup.enter="logIn">
      <v-text-field
        class="email-field mt-8"
        v-model="$v.email.$model"
        :error-messages="emailErrors"
        :label="$t('modules.login.signIn.items.identification')"
        outlined
        required minlength="8"
        maxlength="40"
        type="text"
        autocomplete="email"
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"></v-text-field>
      
      <!-- <div class="d-flex justify-end recovery-btn">
        <h6 class="caption primary--text cursor-pointer" @click="recoveryUser">{{ $t('actions.forgot') }}</h6>
      </div> -->
      <v-text-field
        v-model="$v.pass.$model"
        :error-messages="passErrors"
        :append-icon="hidePass ? 'mdi-eye' : 'mdi-eye-off'"
        :type="hidePass ? 'text' : 'password'"
        :label="$t('modules.login.signIn.items.password')"
        outlined
        required
        autocomplete="current-password"
        @click:append="hidePass = !hidePass"
        @input="$v.pass.$touch()"
        @blur="$v.pass.$touch()"
        class="mt-6"
        ></v-text-field>
      <div class="d-flex justify-end recovery-btn" v-if="!isTeclab">
        <h6 class="caption primary--text cursor-pointer" @click="recoveryPass">{{ $t('actions.forgot') }}</h6>
      </div>
      <div class="d-flex justify-space-around align-center mt-6">
        <v-checkbox
          class="mt-0"
          v-if="!showCheckBox"
          v-model="advisor"
          :label="`${$t('modules.login.signIn.items.advisor')}`"
        />
        <Button
          @clicked="logIn"
          primary class=""
          :loading="loading"
          rounded large
          :text="$t('actions.next')"
          :error="errorAuth"
          :errorText="$t('actions.error')">
        </Button>
      </div>
    </form>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import Button from '@/components/Button/Button'
  import Constants from '@/plugins/constants'
   import { mapGetters } from 'vuex';

  import Configuration from '../../../../core/constants/configuration';

  export default {
    name: 'SignInForm',
    components:{
      Button
    },
    props: {
      loading: Boolean,
      errorAuth: Boolean,
      serverError: Boolean,
    },
    data () {
      return {
        email: '',
        pass: '',
        hidePass: false,
        showCheckBox: Constants.ADVISOR,
        isPortalAdmin: Constants.ADVISOR,
        advisor: false,
      }
    },
    methods: {
      logIn () {
        this.$v.$touch();
        if (this.email === '' || this.pass === '')
          return false;
        const data = { email: this.email.toLowerCase(), pass: this.pass, advisor: this.isPortalAdmin ? true : this.advisor};
        this.$emit('logIn', data)
      },
      clear () {
        this.$v.$reset();
        this.email = '';
        this.pass = '';

        this.$store.dispatch('login/clearErrorLogin')
      },
      recoveryUser () {
        this.$router.push('/login/recovery-user')
        .catch(err=>{
          throw err;
        })
      },
      recoveryPass () {
        this.$router.push('/login/recovery-password')
      }
    },
    validations: {
      email: { required },
      pass: { required },
    },
    computed: {
      isTeclab() {
        return Configuration.value('theme') == 'teclab'
      },
      emailErrors () {
        const errors = [];
        if (!this.$v.email.$anyError ) return errors;
        if(!this.$v.email.required) errors.push(this.$t('modules.login.signIn.errors.requiredEmail'));
        // if(!this.$v.email.email) errors.push(this.$t('modules.login.signIn.errors.invalidEmail'));
        return errors
      },
      passErrors () {
        const errors = [];
        const requiredPasswordError = this.$t('modules.login.signIn.errors.requiredPassword')
        if (!this.$v.pass.$anyError) return errors;
        !this.$v.pass.required && errors.push(requiredPasswordError);
        return errors
      },
      ...mapGetters({
          logoPath: 'commons/getLogoImageUrl',
      }),
    }
  }
</script>
<style scoped lang="sass">
.checkbox-custom
  padding-left: 20px
.welcome-message 
  font-size: 23px
  margin-top: 40px
@media (max-width: 500px)
  .welcome-message
    font-size: 19px
  .checkbox-custom
    transform: none
    padding-left: 0px
    margin-top:-20px
  .sub-message
    font-size: 14px !important
  .form 
    width: 300px !important
    .recovery-link
      font-size: 12px !important
.form 
  width:350px
.sub-message
  font-size: 19px
  font-family: Roboto, sans-serif !important
.email-field
  font-size: 20px
  width: 500px
  margin-top: 2rem
.recovery-link
  color: #0056fe
  font-size: 14px
.login-button-admin
  margin-left: 320px !important
  margin-top: 20px !important
.login-button-student
  margin-top: 45px !important
@media (max-width: 500px)
.login-button-admin
  margin-right: -22px !important
  margin-top: -10px !important
</style>