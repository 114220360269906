<template>
    <v-container fluid class="container-background fullHeight px-5 d-flex">
        <v-row>
            <v-col cols="12" class="my-6 px-16" v-if="!showTutorial">
                <v-row>
                    <v-col cols="12">
                        <v-card outlined class="px-10">
                            <v-row class="my-3">
                                <v-col cols="7">
                                    <h6 class="title mb-3">{{ $t('modules.examsAvailables.empadronamiento.main.welcome', { name }) }}</h6>
                                    <p>{{ $t('modules.examsAvailables.empadronamiento.main.portal') }}</p>
                                    <p>{{ $t('modules.examsAvailables.empadronamiento.main.tutorial') }}</p>
                                    <v-btn class="mt-3" depressed color="primary" @click="showTutorial = true">
                                        {{ $t('modules.examsAvailables.empadronamiento.main.see_tutorial') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="4" class="mx-auto d-flex justify-center align-center">
                                    <v-img max-height="200" max-width="250" src="@/assets/klarway/KlarwayTutorial_000.png"></v-img>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <h3 class="title">{{ $t('modules.examsAvailables.empadronamiento.main.downloads') }}</h3>
                    </v-col>
                    <v-col cols="3">
                        <h4 class="subtitle-2 mb-2">{{ $t('modules.examsAvailables.empadronamiento.main.app.title') }}</h4>
                        <p class="caption">{{ $t('modules.examsAvailables.empadronamiento.main.app.text') }}</p>
                        <a href="https://apps.apple.com/us/app/klarway/id1528198239" target="_blank">
                            <v-img class="ml-1 mt-2 float-left" max-height="70" max-width="100" src="@/assets/klarway/download_app_store.png"></v-img>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.bivproctor" target="_blank">
                            <v-img class="ml-6 mt-2 float-left" max-height="70" max-width="100" src="@/assets/klarway/download_google_play.png"></v-img>
                        </a>
                    </v-col>
                    <v-col cols="4" class="px-0 mx-auto">
                        <h4 class="subtitle-2 mb-2">{{ $t('modules.examsAvailables.empadronamiento.main.requirements_mac.title') }}</h4>
                        <ul class="pl-2" style="list-style-type: none;">
                            <li class="caption"><v-icon class="mr-1" color="warning" size="18">mdi-arrow-right</v-icon>{{ $t('modules.examsAvailables.empadronamiento.main.requirements_mac.1') }}</li>
                            <li class="caption"><v-icon class="mr-1" color="warning" size="18">mdi-arrow-right</v-icon>{{ $t('modules.examsAvailables.empadronamiento.main.requirements_mac.2') }}</li>
                            <li class="caption"><v-icon class="mr-1" color="warning" size="18">mdi-arrow-right</v-icon>{{ $t('modules.examsAvailables.empadronamiento.main.requirements_mac.3') }}</li>
                            <li class="caption"><v-icon class="mr-1" color="warning" size="18">mdi-arrow-right</v-icon>{{ $t('modules.examsAvailables.empadronamiento.main.requirements_mac.4') }}</li>
                            <li class="caption"><v-icon class="mr-1" color="warning" size="18">mdi-arrow-right</v-icon>{{ $t('modules.examsAvailables.empadronamiento.main.requirements_mac.5') }}</li>
                        </ul>
                        <a href="https://apps.apple.com/ar/app/klarway/id1528198239" target="_blank">
                            <v-img class="ml-2 mt-5" max-height="70" max-width="100" src="@/assets/klarway/download-MAC.png"></v-img>
                        </a>
                    </v-col>
                    <v-col cols="4" class="mx-auto">
                        <h4 class="subtitle-2 mb-2">{{ $t('modules.examsAvailables.empadronamiento.main.requirements_win.title') }}</h4>
                        <ul class="pl-2" style="list-style-type: none;">
                            <li class="caption"><v-icon class="mr-1" color="warning" size="18">mdi-arrow-right</v-icon>{{ $t('modules.examsAvailables.empadronamiento.main.requirements_win.1') }}</li>
                            <li class="caption"><v-icon class="mr-1" color="warning" size="18">mdi-arrow-right</v-icon>{{ $t('modules.examsAvailables.empadronamiento.main.requirements_win.2') }}</li>
                            <li class="caption"><v-icon class="mr-1" color="warning" size="18">mdi-arrow-right</v-icon>{{ $t('modules.examsAvailables.empadronamiento.main.requirements_win.3') }}</li>
                            <li class="caption"><v-icon class="mr-1" color="warning" size="18">mdi-arrow-right</v-icon>{{ $t('modules.examsAvailables.empadronamiento.main.requirements_win.4') }}</li>
                            <li class="caption"><v-icon class="mr-1" color="warning" size="18">mdi-arrow-right</v-icon>{{ $t('modules.examsAvailables.empadronamiento.main.requirements_win.5') }}</li>
                        </ul>
                        <a href="https://play.google.com/store" target="_blank">
                            <v-img class="ml-2 mt-5" max-height="70" max-width="100" src="@/assets/klarway/download-windows.png"></v-img>
                        </a>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="mx-auto mb-10 d-flex align-center flex-column" v-else>
                <v-stepper v-model="e1">
                    <v-stepper-header>
                        <v-stepper-step :complete="e1 > 1" step="1">App</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 > 2" step="2">Login</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3">Validación</v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-card outlined class="mb-12 px-10 py-10">
                                <v-row>
                                    <v-col cols="4">
                                        <v-img max-height="150" max-width="250" src="@/assets/klarway/KlarwayTutorial_001.png"></v-img>
                                    </v-col>
                                    <v-col cols="8">
                                        <p v-html="$t('modules.examsAvailables.empadronamiento.step_1.text')"></p>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-row>
                                <v-col cols="6">
                                    <v-btn text @click="e1 = 1; showTutorial = false">
                                        <v-icon right class="ml-0 mr-3">mdi-arrow-left</v-icon>
                                        {{ $t('actions.return') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="6" class="text-right">
                                    <v-btn color="primary" @click="e1 = 2">
                                        {{ $t('actions.continue') }}
                                        <v-icon right class="ml-2">mdi-arrow-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-card outlined class="mb-12 px-10 py-10">
                                <v-row>
                                    <v-col cols="4">
                                        <v-img max-height="150" max-width="250" src="@/assets/klarway/KlarwayTutorial_002.png"></v-img>
                                    </v-col>
                                    <v-col cols="8">
                                        <p v-html="$t('modules.examsAvailables.empadronamiento.step_2.text', {institucion: theme})"></p>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-row>
                                <v-col cols="6">
                                    <v-btn text @click="e1 = 1">
                                        <v-icon right class="ml-0 mr-3">mdi-arrow-left</v-icon>
                                        {{ $t('actions.return') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="6" class="text-right">
                                    <v-btn color="primary" @click="e1 = 3">
                                        {{ $t('actions.continue') }}
                                        <v-icon right class="ml-2">mdi-arrow-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                        <v-stepper-content step="3">
                            <v-card outlined class="mb-12 px-10 py-10">
                                <v-row>
                                    <v-col cols="4">
                                        <v-img max-height="150" max-width="250" src="@/assets/klarway/KlarwayTutorial_003.png"></v-img>
                                    </v-col>
                                    <v-col cols="8">
                                        <p v-html="$t('modules.examsAvailables.empadronamiento.step_3.text_1')"></p>
                                        <ul class="pl-2 mb-4" style="list-style-type: none;">
                                            <li><v-icon class="mr-1" color="warning" size="20">mdi-arrow-right</v-icon>Imágen del rostro</li>
                                            <li><v-icon class="mr-1" color="warning" size="20">mdi-arrow-right</v-icon>Información personal</li>
                                        </ul>
                                        <p v-html="$t('modules.examsAvailables.empadronamiento.step_3.text_2')"></p>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-row>
                                <v-col cols="6">
                                    <v-btn text @click="e1 = 2">
                                        <v-icon right class="ml-0 mr-3">mdi-arrow-left</v-icon>
                                        {{ $t('actions.return') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="6" class="text-right">
                                    <v-btn color="primary" @click="$router.push('/exams-available')">{{ $t('actions.finish') }}</v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                    </v-stepper-items>
                </v-stepper>
            </v-col>
            
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { $user } from '../Services'

    import Configuration from '../../../core/constants/configuration';

    export default {
        name: 'Empadronamiento',
        data() {
            return {
                showTutorial: false,
                innerWidth: 500,
                step: 0,
                e1: 1,
                name: '',
                hash: ''
            }
        },
        computed: {
            ...mapGetters({
               userId: 'commons/getStudentUserID',
               userData : 'commons/getStudentData',
               getHash : 'commons/getHash'
            }),
            theme() {
                return Configuration.value('theme')
            },
        },
        mounted() {
            this.hash = this.getHash
        },
        created() {
            this.setWindowSize()
            window.addEventListener('resize', this.setWindowSize)
            this.name = this.userData.name.charAt(0).toUpperCase() + this.userData.name.slice(1).toLowerCase();
        },
        methods: {
            setWindowSize() {
                this.innerWidth = window.innerWidth - 72 - 200
            },
            showPrev() {
                // this.$refs.carousel.next()
                // owl.trigger('prev.owl.carousel', [300]);
            },
            showNext() {
                // this.$refs.carousel.next()
                // owl.trigger('prev.owl.carousel', [300]);
            },
            initExam(id) {
                if (this.isAdmin) {
                    this.$router.push(`/exam/${id}`);
                } else {
                    this.isWarning = true
                    this.successMessage.actionPrimary = { text: this.$t('modules.examsAvailables.success_message.start'), callback: () => { this.initialize(id) }}
                    this.successMessage.actionSecondary = { text: this.$t('modules.examsAvailables.success_message.cancel'), callback: () => { this.isWarning = false }}
                }
            },
            async habilitarExamen(examId) {
                const response2 = await $user.launchFacialRecognition(this.userId, this.hash, examId);
                if (!response2.data.error) this.mostrarBotonRendir = true;
            },
        },
    };
</script>

<style lang="sass" scoped>
// Basic VueAgile styles
.agile
	width: 100%

	&__actions
		margin-top: 20px

	&__nav-button
		background: transparent
		border: none
		color: #ccc
		cursor: pointer
		font-size: 24px
		transition-duration: .3s

		&:hover
			color: #888

	&__dot
		margin: 0 10px

		button 
			background-color: #eee
			border: none
			border-radius: 50%
			cursor: pointer
			display: block
			height: 10px
			font-size: 0
			line-height: 0
			margin: 0
			padding: 0
			transition-duration: .3s
			width: 10px

		&--current,
		&:hover
			button
				background-color: #888

// Slides styles	
.slide
	align-items: center
	color: #fff
	display: flex
	height: 300px
	justify-content: center

	h3
		font-size: 32px
		font-weight: 300

// Slides backgrounds
$colors: #f1c40f #e67e22 #e74c3c #9b59b6 #3498db #2ecc71

@for $i from 1 through length($colors)
	$color: nth($colors, $i)

	.slide--#{$i}
		background-color: $color
</style>