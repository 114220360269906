import baseUrl from '@/constants/uri';

import Configuration from '../constants/configuration';

export default {
  HTTP_METHOD_POST: 'POST',
  HTTP_METHOD_PUT: 'PUT',
  HTTP_METHOD_PATCH: 'PATCH',
  HTTP_METHOD_GET: 'GET',
  HTTP_METHOD_DELETE: 'DELETE',

  // URL_BASE: "http://10.57.14.71:8000",
  // URL_IDENTITY_SERVER: "http://10.57.14.71:8000/oauth/token",
  // URL_DATA_SERVER: "http://10.57.14.71:8000/core",

  URL_BASE: baseUrl,
  URL_IDENTITY_SERVER: `${baseUrl}/oauth/token`,
  URL_PRICING_SERVER: `${baseUrl}/pricing`,
  URL_ACADEMIC_SERVER: `${baseUrl}/academic`,
  URL_DATA_SERVER: `${baseUrl}/core`,
  URL_LOGOUT_REDIRECT: '/',
  URL_LOGIN_REDIRECT: '/',
  ADVISOR: !!(Configuration.value('app') == 'portaladministrativo'),
  getFetchOptions (body, method, contentType) {
    const token = localStorage.getItem('access_token')
      ? JSON.parse(localStorage.getItem('access_token'))
      : '';
    if (!contentType)
      contentType = 'application/json';
    const options = {
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': contentType,
        'Authorization': `Bearer ${token}`,
      }
    };
    if (method) {
      options.method = method;
    }
    if (body) {
      options.body = JSON.stringify(body);
    }
    return options;
  },
  getBasicOptions (body, method, contentType) {
    if (!contentType)
      contentType = 'application/json';
    const options = {
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': contentType,
        'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0',
      }
    };
    if (method) {
      options.method = method;
    }
    if (body) {
      options.body = JSON.stringify(body);
    }
    return options;
  }
}
