
// ippService es una instancia de "trae"
import ippService from './ipp.service'
import Constants from './constants'


const settingsService = {
  install (Vue) {
    Vue.prototype.$settings = this;
  },
}

settingsService.sendEmailTest = function (data) {
  return ippService.post('/test-email',
    data,
    { ...Constants.getFetchOptions()}
  )
},

settingsService.sendSMSTest = function (data) {
  return ippService.post('/test-sms',
    data,
    { ...Constants.getFetchOptions()}
  )
},

settingsService.getAllData = function () {
  return ippService.get('/configurations',
    { ...Constants.getFetchOptions() }
  )
    .then(function (response) {
      // Se puede tratar la data extraida para que tenga el formato que esperamos
      return response.data[0];
    })
    .catch((err) => {
      return err
    })
}

settingsService.changeData = function (id = 1, data) {

  /*
  DATAFIELDS

  data = {
      "version": "1.0",
      "about": "This is the about section",
      "language":"English",
      "dateFormat": "",
      "timeFormat": "",
      "timeZone": "",
      "smsTemplate": "",
      "privacyPolicy": "",
      "termsConditions": "",
      "smtpFrom": "",
      "smtpName": "",
      "smtpHost": "",
      "smtpPort": 0,
      "smtpUserName": "",
      "smtpPassword": "",
      "smtpAuth": true,
      "smtpTLSEnable": true,
      "maxLoginAttempts": 3,
      "maxAttemptsHours": 3,
      "smsConfig": [
          {
              "phoneNumber": "+5435198542",
              "sidAccount": "FFG3WS",
              "authToken": "cjkH970gJK`p99D",
              "status": true
          }
      ]
  }
  */

  return ippService.put(`/configurations/${id}`,
    data,
    { ...Constants.getFetchOptions() })
    .then(function (response) {
      return response.data;
    })
    .catch((err) => {
      return err
    })
}

export default settingsService
