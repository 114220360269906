const TelephoneInputTranslations = {
    es: {
        formatInvalid: 'El número no corresponde con el formato de país',
        isRequired: 'Debes ingresar el número'
    },
    en: {

    }
};

export default TelephoneInputTranslations
