// import LoginApplication from '../LoginApplication'
import LoginSingIn from '../Views/LoginSignIn'
import LoginRecovery from '../Views/LoginRecovery'
import LoginSecurityCode from '../Views/LoginSecurityCode'
import LoginRecovered from '../Views/LoginRecovered'
import LoginAmplifyForm from '../Views/LoginAmplifyForm'

const LoginRoute = [
    {
        path: '',
        name: 'Index',
        component: LoginSingIn,
    },{
        path: 'recovery-password',
        name: 'Password Recovery',
        component: LoginRecovery
    },{
        path: 'recovery-user',
        name: 'User Recovery',
        component: LoginRecovery
    },{
        path: 'security-code-password',
        name: 'Password Security Code',
        component: LoginSecurityCode
    },{
        path: 'security-code-user',
        name: 'Security Code User',
        component: LoginSecurityCode
    },{
        path: 'new-password',
        name: 'New Password',
        component: LoginRecovered
    },{
        path: 'recovered-user',
        name: 'User Recovered',
        component: LoginRecovered
    },{
        path: '*',
        name: 'IndexWildCard',
        component: LoginSingIn,
    },
    {
        path: 'login-amplify',
        name: 'LoginAmplifyForm',
        component: LoginAmplifyForm,
        props: true
    }
]

export default LoginRoute
