const examAvailablesTranslations = {
    es_CL: {
        empadronamiento: {
            step_2: {
                text: '<b>1)</b> Hace click en <b>Ingresar ahora</b>.<br><br><b>2)</b> Donde dice "Nombre de la Institución" escribí <b class="text-uppercase">"{institucion}"</b>.<br><br><b>3)</b> En usuario, escribí tu nombre y apellido (todo junto), colocá tu RUT y en contraseña repetí tu mismo nombre de Usuario.',
            },
        },
        terms: {
            time_duration: 'Tendrás ’45 (minutos) para hacer tu examen',
        }
    },
    es_MX: {
        empadronamiento: {
            portal: 'Bienvenido al portal de Exámenes, para realizar los exámenes, necesitaras descargár la aplicación de Klarway',
            app: {
                text: 'Descarga Klarway desde el store de tu movil',
            },
            step_1: {
                text: 'Descarga la aplicación de <b>Karway</b> del store de tu movil, dispoble para ambos sistemas operativos.',
            },
            step_2: {
                text: '<b>1)</b> Hace click en <b>Ingresar ahora</b>.<br><br><b>2)</b> Donde dice "Nombre de la Institución" escribí <b class="text-uppercase">"{institucion}"</b>.<br><br><b>3)</b> En usuario, escribí tu nombre y apellido (todo junto), colocá tu CURP y en contraseña repetí tu mismo nombre de Usuario.',
            },
            step_3: {
                text_2: '<b>¡Listo! Podrás ingresar a realizar tus exámenes.</b>',
            },
        },
        terms: {
            title: 'Te recordamos que queda terminantemente prohibido el uso del movil durante el examen',
            time_duration: 'Tendrás ’90 (minutos) para hacer tu examen',
            subtitle: 'Tiempo para realizar los exámenes',
            time_start: 'El tiempo se comienza a contar desde que haces click en el botón REALIZAR ubicado a la derecha del nombre de la materia a realizar',
        },
        students: {
            headline: {
                title: 'Exámenes inscritos',
                name: 'Carrera: {career}',
            },
            examcard: {
                button: 'Realizar',
            },
        },
    },
    es: {
        empadronamiento: {
            main: {
                welcome: 'Hola {name}',
                portal: 'Bienvenido al portal de Exámenes, para rendir los exámenes, necesitaras descargár la aplicación de Klarway',
                tutorial: 'Mirá el tutorial para descargar y configurar y configurar la app',
                see_tutorial: 'Ver tutorial',
                downloads: 'Descargas',
                app: {
                    title: 'Aplicación Móvil',
                    text: 'Descarga Klarway desde el store de tu celular',
                },
                requirements_mac: {
                    title: 'Requerimientos MacOS',
                    '1': 'Sistema operativo: macOS Catalina 10.15.4 o superior',
                    '2': 'Procesador: Intel/AMD 2GB RAM, Mac Intel Core I3',
                    '3': 'Cámara y micrófono',
                    '4': 'Conexión a Internet',
                    '5': 'Bateria cargada 100%',
                },
                requirements_win: {
                    title: 'Requerimientos Windows',
                    '1': 'Sistema operativo: Windows 10',
                    '2': 'Procesador: Core I3, 64 bits, 4GB de RAM',
                    '3': 'Resolución de cámara 800x600',
                    '4': 'Conexión a Internet',
                    '5': 'Bateria cargada 100%',
                },
            },
            step_1: {
                text: 'Descarga la aplicación de <b>klarway</b> en tu escritorio, disponible para ambos sistemas operativos',
            },
            step_2: {
                text: '<b>1)</b> Hace click en <b>Ingresar ahora</b>.<br><br><b>2)</b> Donde dice "Nombre de la Institución" escribí <b class="text-uppercase">"{institucion}"</b>.<br><br><b>3)</b> En usuario, escribí tu nombre y apellido (todo junto), colocá tu DNI y en contraseña repetí tu mismo nombre de Usuario.',
            },
            step_3: {
                text_1: 'Realizá los pasos solicitados para tu <b>Validación Biométrica</b>',
                list_1: 'Imágen del rostro',
                list_2: 'Información personal',
                text_2: '<b>¡Listo! Podrás ingresar a rendir tus exámenes.</b>',
            },
            not_registered:{
                text: 'Continuá con el proceso de alta para rendir exámenes',
                button: 'Continuar'
            }
        },
        loading: 'Cargando...',
        students: {
            headline: {
                title: 'Exámenes inscritos',
                name: 'Carrera: {career}',
            },
            examcard: {
                date: 'Fecha',
                type: 'General',
                instance: 'Examen final',
                button: 'Rendir',
                review: 'Revisar examen',
                waiting_habilitation: 'Esperando habilitación...',
                not_finished: 'Examen no finalizado',
                finished: 'Examen finalizado',
                not_initialized: 'Examen no inicializado',
                habilitar: 'Habilitar',
                finalize: 'Finalizar',
            },
        },
        terms: {
            title: 'Te recordamos que queda terminantemente prohibido el uso del celular durante el examen',
            
            subtitle: 'Tiempo para rendir los exámenes',
            time_start: 'El tiempo se comienza a contar desde que hacés click en el botón RENDIR ubicado a la derecha del nombre de la materia a rendir',
            time_duration: 'Tendrás ’45 (minutos) para hacer tu examen',
            time_aclaration: 'Ten en cuenta que al cabo de este tiempo, el examen se cerrará automaticamente aunque no lo hayas finalizado',

            important: 'IMPORTANTE: Información al finalizar tu examen',
            text4: 'Al presionar el botón FINALIZAR EXAMEN visualizarás una pantalla con la siguiente información:',
            approved: 'Aprobado o Desaprobado',
            score: 'Nota obtenida',
            correct_answers: 'Preguntas correctas',
            incorrect_answers: 'Preguntas incorrectas',
            before_exit: 'Te recomendamos que antes de salir de esta pantalla verifiques esta informacion con atención y tengas en cuenta que, en caso de haber recibido advertencias de comportamiento durante la realización del examen, el mismo estará en revisión hasta que los registros sean revisados por nuestro equipo.',

            review: {
                title: 'Revisión de preguntas al finalizar el examen',
                subtitle: 'En la pantalla final de tu examen podrás verificar las respuestas elegidas, la justificación (en caso de que tengas preguntas incorrectas) y además podrás pedir la revisión de las mismas si fuera necesario.',
                time: 'El tiempo de revisión es de 15 minutos dependiendo del examen y recordá hacer click en “enviar” al finalizar. Recibirás la respuesta dentro de los 7 días hábiles.',
            }
        },
        noexam: {
            title: 'No tienes exámenes disponibles hoy',
        },
        success_message: {
            finalize_title: '¿Deseas finalizar el examen?',
            finalize_text: 'No se podrá revertir',
            cancel: 'Cancelar',
            continue: 'Continuar',
            start: 'Comenzar',
            initialize: {
                klarway: {
                    start_validation_title: '¿Confirmas que deseas comenzar el examen?',
                    start_validation_subtitle: 'Una vez que presiones "comenzar", se iniciará la validación de entorno y luego empezará a correr el tiempo correspondiente.',
                    start_exam_title: 'Validación de entorno',
                    start_exam_subtitle: 'Ya finalizó la validación del entorno, haga clic en continuar para pasar al examen.',
                    validation_error: 'Ocurrió un error al finalizar la validación del entorno. Por favor, volvé a iniciar el examen.',
                },
                sumadi: {
                    title: '¿Confirmas que deseas comenzar el examen?',
                    subtitle: 'Una vez que presiones "comenzar", empezara a correr el tiempo correspondiente.',
                },
            }
        },
        popUp:{
            errorMatchProctoring:{
                title:'Para iniciar este examen, debes ingresar desde la aplicación de ',
                // content:'El examen y el proctoring que se esta usando no son compatibles'
            }
        }
        
    },
    en: {
        
    }
};

export default examAvailablesTranslations