import transferlistTranslations from './TransferList/Translations/index'
import appBarTranslations from './AppBar/Translations/index'
import alertPopupTranslations from './AlertPopup/Translations/index'
import TelephoneInputTranslations from './TelephoneInput/Translations/index'
import AutocompleteInputTranslations from './AutocompleteInput/Translations/index.js'
import notFoundStateTranslations from './NotFoundState/Translations/index.js'

const es = {
  autocompleteInput: { ...AutocompleteInputTranslations.es },
  transferlistTranslations: { ...transferlistTranslations.es },
  appBar: { ...appBarTranslations.es },
  alertPopup: { ...alertPopupTranslations.es },
  telephoneInput: { ...TelephoneInputTranslations.es },
  notFoundState: { ...notFoundStateTranslations.es },
};

const en = {
  transferlistTranslations: { ...transferlistTranslations.en },
  autocompleteInput: { ...AutocompleteInputTranslations.en },
  appBar: { ...appBarTranslations.en },
  alertPopup: { ...alertPopupTranslations.en },
  telephoneInput: { ...TelephoneInputTranslations.en },
  notFoundState: { ...notFoundStateTranslations.en },
};

export default { es, en }
