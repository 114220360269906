import Configuration from '../../core/constants/configuration';

export default {
  getApplicationImageUrl () {
    return `https://${Configuration.value('awsPublicUrl')}.${Configuration.value('url')}/core/applicationImage_examenes.png`
  },
  getLogoImageUrl () {
    return `https://${Configuration.value('awsPublicUrl')}.${Configuration.value('url')}/core/logoImage.png`
  },
  getAvatarImageUrl () {
    return `https://${Configuration.value('awsPublicUrl')}.${Configuration.value('url')}/core/defaultAvatar.png`
  },
  getUserData (state) {
    if (state.loggedUserData.userData) return state.loggedUserData.userData;
    else return {};
  },
  getStudentUserID (state) {
    return state.userData.id;
  },
  isUserAdmin (state) {
    return state.isAdmin;
  },
  isKlarway (state) {
    return state.isKlarway;
  },
  getHash(state){
    return state.hash
  },
  getStudentData(state) {
    if (state.userData.userData) return state.userData.userData;
    else return {};
  },
  getStudentRegisters(state) {
    if (state.userData.legajos) return state.userData.legajos;
    else return [];
  },
  getStudentId (state) {
    return state.userData && state.userData.legajos ? state.userData.legajos[0].studentId : null;
  },
  getProp: () => (keypath, object) => {
    keypath = keypath.split('.');
    return keypath.reduce((obj, key) => (obj && obj[key]) ? obj[key] : null, object);
  },
  getLoadingState(state) {
    return {
      status: state.loading.counter > 0,
      drawer: state.loading.drawer
    };
  },
  getApplicationModules (state) {
    const {loggedUserData} = state;

    const applications = [];
    const getSubModulesPermissions = (children, loadedChildren = []) => {
      if (loadedChildren.length) {
        children.forEach(child => {
          const modPermission = child.permissions.find(item => {return item.name == 'READ' && item.status})
          if (child.status && modPermission) {
            const exists = loadedChildren.find(item => {return item.name == child.name})
            if (!exists) {
              loadedChildren.push({
                status: child.status,
                name: child.name,
                path: child.path,
                order: child.order,
                imagePath: child.imagePath,
                children: []
              })
            }
          }
        });
        return loadedChildren
      } else {
        if(children) {
          children.forEach(subModule => {
            const modPermission = subModule.permissions.find(item => {return item.name == 'READ' && item.status})
            if (subModule.status && modPermission) {
              loadedChildren.push({
                status: subModule.status,
                name: subModule.name,
                path: subModule.path,
                order: subModule.order,
                imagePath: subModule.imagePath,
                children: []
              })
            }
          })
        }
        return loadedChildren
      }
    }

    if (loggedUserData.roles) {
      loggedUserData.roles.forEach(role => {

        if(!Array.isArray(role.applications)) return;
        role.applications.forEach(application => {
          if (application.name == 'Exámenes') {

            if(!Array.isArray(application.modules)) return;
            application.modules.forEach(module => {
              const loadedModule = applications.find((item) => {return item.name == module.name})
              if (loadedModule) {
                // Modulo ya cargado por otro rol
                loadedModule.children = getSubModulesPermissions(module.children, loadedModule.children)
              } else if (module.id !== 86) {
                const modPermission = module.permissions.find(item => {return item.name == 'READ' && item.status})
                if (module.status && modPermission) {
                  // Modulo no cargado
                  const moduleData = {
                    status: module.status,
                    name: module.name,
                    path: module.path,
                    order: module.order,
                    imagePath: module.imagePath,
                    children: getSubModulesPermissions(module.children)
                  };
                  applications.push(moduleData);
                }
              }
            });
          }
        });
      });
    }

    return applications;
  },

  getPermissions (state) {
    const {loggedUserData} = state;
    const permissions = [];

    if (loggedUserData.roles && Array.isArray(loggedUserData.roles)) {
      loggedUserData.roles.forEach(role => {

        if(!Array.isArray(role.applications)) return;

        role.applications.forEach(application => {
          if (application.name == 'Exámenes') {
            if(!Array.isArray(application.modules)) return;
          
            application.modules.forEach(module => {
              let readPermission = null
              if (module.permissions) {
                readPermission = module.permissions.find(permission=>{
                  return permission.name === 'READ' && permission.status
                })
              } else {
                console && console.warn && console.warn(`El modulo "${module.name}" no tiene permiso de lectura asociado`)  // eslint-disable-line
              }
              const moduleData = {
                name: module.name,
                permission: module.status && readPermission,
                children: []
              };

              if(module.children && module.children.length) {
                module.children.forEach(subModule => {
                  moduleData.children.push({
                    name: subModule.name,
                    permission: subModule.status
                  });
                });
              }

              permissions.push(moduleData);
            });
          }
        });
      });
    }

    return permissions;
  },

  getCRUDPermissions(state) {
    return state.permissions
  },

};
