import { mapGetters } from 'vuex';

import Configuration from '../constants/configuration';
import { $user } from '../services';

export default {
    methods: {
        $accessDenied() {
            this.$router.replace('/access-denied')
        },
        $trackEvent(category, action, label) {
            this.$gtag ? this.$gtag.event(action, {
                'event_category': category,
                'event_label': label,
                'value': 1
            }) : '';
        },
        // async $registryUser() {
        //     try{
        //         const userData = this['commons/getStudentData']
        //         const userId = this['commons/getStudentUserID']
        //         const request = {
        //             name : userData.name,
        //             lastname : userData.lastname,
        //             email : userData.email,
        //             username : userData.identification,
        //             id : userId
        //         }
        //         console.log('registro de usuario en klarway');
        //         console.log(request);
        //         const response = await $user.saveUser(request);
        //         console.log(response);
        //         console.log('usuario registrado correctamente');
        //         return true;
        //       }
        //       catch (error) {
        //         console.log('Ocurrio un error al registrar usuario');
        //         console.log(error);
        //         return false;
        //       }
        // },
        async $activatePadron(){
            const response = await $user.launchActivatePadron(this.userId, this.getHash)
            return response;
        },
    },
    computed: {
        ...mapGetters({
            mixinPermissions: 'commons/getCRUDPermissions',
            userData: 'commons/getStudentData',
            userId: 'commons/getStudentUserID',
            getHash : 'commons/getHash'
        }),
        $isTeclab() {
            return this.$theme == 'teclab'
        },
        $country() {
            return this.$country 
        },
        $isIPP() {
            return this.$theme == 'ipp'
        },
        $theme() {
            return Configuration.value('theme')
        },
        $aivo() {
            return Configuration.value('aivo')
        },
        $permissions() {
            const permissions = this.mixinPermissions
            const defaultData = {
                applications: {STATUS: false, READ: false},
                available_exams: {STATUS: false, READ: false, CREATE: false},
            }

            if (permissions) {
                if (permissions['1']) defaultData.available_exams = permissions['1']
            }
            return defaultData
        }
    },
}