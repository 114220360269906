<template>
    <v-container>
        <div v-if="!overlay">
            <v-card outlined class="px-3">
                <v-row align="center">
                    <v-col cols="2" class="py-1">
                        <img height="30" :src="logoPath" />
                    </v-col>
                    <v-col cols="10" align="end" class="py-1">
                        <strong class="mr-2">{{ $t('modules.exams.review.subject') }}:</strong>
                        <span class="mr-10">{{ exam.subject }}</span>
                        <strong class="mr-2 ml-10">{{ $t('modules.exams.review.student') }}:</strong>
                        <span class="mr-5">{{ fullName }}</span>
                    </v-col>
                </v-row>
            </v-card>
            <v-row>
                <v-col cols="3">
                    <h2>{{ $t('modules.exams.review.brief.title') }}</h2>

                    <v-card outlined class="ExamRevision-card-rounded" v-if="exam.oldModality">
                        <v-row align="center" justify="center" class="py-4">
                            <v-col cols="12" align="center" class="py-0">
                                <span class="primary--text">{{ $t('modules.exams.review.brief.final_status') }}</span>
                            </v-col>
                            <v-col cols="12" align="center" class="py-0">
                                <h1 :class="exam.finalCalification >= 4 ? 'success--text' : 'error--text'">
                                    {{ exam.finalCalification >= 4 ? $t('modules.exams.review.brief.approved') : $t('modules.exams.review.brief.failed') }}
                                </h1>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card outlined class="ExamRevision-card-rounded" v-if="exam.oldModality">
                        <v-row align="center" justify="center" class="py-4">
                            <v-col cols="12" align="center" class="py-0">
                                <span class="primary--text">{{ $t('modules.exams.review.brief.final_calification') }}</span>
                            </v-col>
                            <v-col cols="12" align="center" class="py-0">
                                <h1 class="primary--text data-hj-whitelist">{{ exam.finalCalification }}</h1>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card outlined class="ExamRevision-card-rounded">
                        <v-row align="center" justify="center" class="py-4">
                            <v-col cols="12" align="center" class="py-0">
                                <span class="primary--text">{{ $t('modules.exams.review.brief.status') }}</span>
                            </v-col>
                            <v-col cols="12" align="center" class="py-0">
                                <h1 :class="exam.status ? 'success--text' : 'error--text'">
                                    {{ exam.status ? $t('modules.exams.review.brief.approved') : $t('modules.exams.review.brief.failed') }}
                                </h1>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card outlined class="ExamRevision-card-rounded">
                        <v-row align="center" justify="center" class="py-4">
                            <v-col cols="12" align="center" class="py-0">
                                <span class="primary--text">{{ $t('modules.exams.review.brief.calification') }}</span>
                            </v-col>
                            <v-col cols="12" align="center" class="py-0">
                                <h1 class="primary--text data-hj-whitelist">{{ exam.calification }}</h1>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card outlined class="ExamRevision-card-rounded">
                        <v-row align="center" justify="center" class="py-4">
                            <v-col cols="12" align="center" class="py-0">
                                <span class="primary--text">{{ $t('modules.exams.review.brief.totalQuestions') }}</span>
                            </v-col>
                            <v-col cols="12" align="center" class="py-0">
                                <h1 class="primary--text data-hj-whitelist">{{ exam.totalQuestions }}</h1>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card outlined class="ExamRevision-card-rounded">
                        <v-row align="center" justify="center" class="py-4">
                            <v-col cols="12" align="center" class="py-0">
                                <span class="primary--text">{{ $t('modules.exams.review.brief.totalAnswers') }}</span>
                            </v-col>
                            <v-col cols="12" align="center" class="py-0">
                                <h1 class="primary--text data-hj-whitelist">{{ exam.totalAnswered }}</h1>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card outlined class="ExamRevision-card-rounded">
                        <v-row align="center" justify="center" class="py-4">
                            <v-col cols="12" align="center" class="py-0">
                                <span class="primary--text">{{ $t('modules.exams.review.brief.correctAnswers') }}</span>
                            </v-col>
                            <v-col cols="12" align="center" class="py-0">
                                <h1 class="primary--text data-hj-whitelist">{{ exam.correctNumber }}</h1>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card outlined class="ExamRevision-card-rounded">
                        <v-row align="center" justify="center" class="py-4">
                            <v-col cols="12" align="center" class="py-0">
                                <span class="primary--text">{{ $t('modules.exams.review.brief.incorrectAnswers') }}</span>
                            </v-col>
                            <v-col cols="12" align="center" class="py-0">
                                <h1 class="primary--text data-hj-whitelist">{{ exam.incorrectNumber }}</h1>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="9">
                    <h2>{{ $t('modules.exams.review.answers.title') }}</h2>

                    <v-expansion-panels accordion class="mt-4">
                        <v-expansion-panel v-for="(item, i) in exam.answers" :key="i" class="ExamRevision-expansion-panel">
                            <v-expansion-panel-header class="py-2">
                                <span class="d-flex flex-row align-center data-hj-whitelist">
                                    <v-icon large :color="item.isCorrect ? 'success' : 'error'" class="ml-2 mr-5 d-block data-hj-whitelist">
                                        {{ item.isCorrect ? 'mdi-check-circle' : 'mdi-close-circle' }}
                                    </v-icon>
                                    <span class="mr-2">{{ i + 1 }}.</span>
                                    <img v-if="item.mediaDescription" :src="`data:image/jpeg;base64,${item.mediaDescription.imagen}`" alt="Imagen" style="max-width: 85%">
                                    <span v-else>{{ item.description }}</span>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="ExamRevision-expansion-panel-content">
                                <v-row>
                                    <v-col class="pb-1">
                                        <span class="status-dot" :class="item.isCorrect ? 'success' : 'error'"></span>
                                        <strong class="mr-2">{{ $t('modules.exams.review.answers.chosenAnswer') }}:</strong>
                                    </v-col>
                                    <v-col cols="12" class="d-flex align-center py-1">
                                        <div v-if="item.mediaSelectedAnswer">
                                            <img v-if="item.mediaSelectedAnswer.imagen&&item.mediaSelectedAnswer.imagen?.length == 1" :src="item.mediaSelectedAnswer.images[0]" alt="Imagen" style="max-width: 80%"> 
                                             <div v-else>
                                                <ul v-if="item.mediaSelectedAnswer.images" type="none" class="d-flex align-content-start flex-wrap">
                                                    <li v-for="(selectedMedia, i) in item.mediaSelectedAnswer.images " :key="i" >
                                                        <img :src="selectedMedia" alt="Imagen" style="max-width: 80%">
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div v-else >
                                            <span v-if="item.selectedAnswer"> {{item.selectedAnswer}}</span>
                                           <div v-else >
                                                <ul v-for="(selected, i) in item.selectedAnswer" :key="i" type="circle">
                                                    <li>{{ selected }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col class="pb-1">
                                        <span class="status-dot success"></span>
                                        <strong class="mr-2">{{ $t('modules.exams.review.answers.correctAnswer') }}:</strong>
                                    </v-col>
                                    <v-col cols="12" class="d-flex align-center py-1">
                                        <div v-if="item.mediaCorrectAnswer">
                                            <img v-if="item.mediaCorrectAnswer.imagen&&item.mediaCorrectAnswer.imagen?.length == 1" :src="item.mediaCorrectAnswer.images[0]" alt="Imagen" style="max-width: 80%"> 
                                             <div v-else>
                                                <ul v-if="item.mediaCorrectAnswer.images" type="none" class="d-flex align-content-start flex-wrap">
                                                    <li v-for="(correctMedia, i) in item.mediaCorrectAnswer.images " :key="i" class="mr-3">
                                                        <img :src="correctMedia" alt="Imagen" style="max-width: 80%">
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div v-else >
                                            <span v-if="item.correctAnswe&&item.correctAnswer?.length == 1"> {{item.correctAnswer[0]}}</span>
                                            <div v-else>
                                                <ul v-for="(correct, i) in item.correctAnswer" :key="i" type="circle">
                                                    <li>{{ correct }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <h3 class="mb-2">{{ $t('modules.exams.review.answers.justification') }}</h3>
                                        <div v-if="item.mediaJustification"></div>
                                        <img v-if="item.mediaJustification" :src="`data:image/jpeg;base64,${item.mediaJustification.imagen}`" alt="Imagen" style="max-width: 85%">
                                        <span v-else class="grey--text">{{ item.justification }}</span>
                                    </v-col>
                                </v-row>
                                <v-row v-if="!item.isCorrect && !isAdmin">
                                    <v-col cols="12">
                                        <v-checkbox
                                            class="mt-0 ExamRevision-checkbox data-hj-whitelist"
                                            v-model="item.review"
                                            color="orange"
                                            :label="$t('modules.exams.review.answers.revision')"
                                        ></v-checkbox>
                                        <div v-if="item.review">
                                            <v-divider />
                                            <v-textarea
                                                class="mt-8"
                                                outlined
                                                :error-messages="item.reviewDirty && (!item.reviewContent || item.reviewContent == '') ? $t('modules.users.update.sectionContact.validation_errors.email') : ''"
                                                :label="$t('modules.exams.review.answers.reviewContent')"
                                                @blur="$set(item, 'reviewDirty', true)"
                                                v-model="item.reviewContent"
                                            ></v-textarea>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-row>
                        <v-col cols="12" align="start" class="mt-4">
                            <v-btn large rounded color="primary" :loading="loading" @click="finishReview">
                                {{ $t('modules.exams.review.answers.finish') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-overlay :value="overlay">
            <h2 class="loading-message">{{ $t('modules.exams.assestment.loading.message') }}</h2>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
    import { $exams, $exam, $multimedia} from '../Services'
    import Constants from '@/plugins/constants'
    import { mapGetters } from 'vuex'
    // import download from 'downloadjs';

    export default {
        name: 'ExamReview',
        data () {
            return {
                hash: '',
                overlay: true,
                excelFile: null,
                loading: false,
                exam: {
                    student: null,
                },
                fields: {
                    'Nro. pregunta': 'questionNumber',
                    'Justificacion del sistema': 'system',
                    'Argumento alumno': 'studentDischarge',
                    'Correcta': 'correctAnswer',
                    'Hay error': 'hasError',
                    'Justificacion profesor': 'teacherJustification'
                },
                json_data: [],
            }
        },
        computed: {
            ...mapGetters({
                isKlarway: 'commons/isKlarway',
                isAdmin : 'commons/isUserAdmin',
                getHash: 'commons/getHash',
                studentUserData: 'commons/getStudentData',
                userId: 'commons/getStudentUserID',
                logoPath : 'commons/getLogoImageUrl',
            }),
            fullName() {
                if (this.studentUserData) return `${this.studentUserData.name.charAt(0).toUpperCase() + this.studentUserData.name.slice(1)} ${this.studentUserData.lastname.charAt(0).toUpperCase()}${this.studentUserData.lastname.slice(1)}`
                else return '-'
            },
        },
        methods: {
            hasRevisions () {
                let reviews = 0
                this.exam.answers.forEach(answer => {
                    if (answer.review) {
                        reviews++
                        if (!answer.reviewContent || answer.reviewContent == '') {
                            reviews = -1
                            return false
                        }
                    }
                });
                return reviews
            },
            onBeforeGenerate () {
                this.json_data = this.exam.answers.map((answer, index) => ({
                    questionNumber: index + 1,
                    system: answer.justification ? answer.justification : ' ',
                    studentDischarge: answer.reviewContent ? answer.reviewContent : ' ',
                    correctAnswer: ' ',
                    hasError: ' ',
                    teacherJustification: ' '
                }))
            },
            valueReformattedForMultilines(value) {
                if (typeof(value)=='string') return(value.replace(/\n/ig,'<br/>'));
                else return(value);
            },
            jsonToXLS(data) {
                const xlsTemp = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>br {mso-data-placement: same-cell;}</style></head><body><table>${table}</table></body></html>';
                let xlsData = '<thead>';
                const colspan = Object.keys(data[0]).length;
                const _self = this;

                //Header
                if (this.title != null) xlsData += this.parseExtraData(this.title, `<tr><th colspan="${colspan}">` + '${data}' + `</th></tr>`);

                //Fields
                xlsData += '<tr>';
                for (const key in data[0]) {
                    xlsData += `<th>${key}</th>`
                }
                xlsData += '</tr>';
                xlsData += '</thead>';

                //Data
                xlsData += '<tbody>';
                data.map(function(item) {
                    xlsData += '<tr>';
                    for (const key in item) {
                        xlsData += `<td>${_self.valueReformattedForMultilines(item[key])}</td>`
                    }
                    xlsData += '</tr>';
                });
                xlsData += '</tbody>';

                //Footer
                if (this.footer != null) {
                    xlsData += '<tfoot>';
                    xlsData += this.parseExtraData(this.footer, `<tr><td colspan="${colspan}">` + '${data}' + `</td></tr>`);
                    xlsData += '</tfoot>';
                }

                return xlsTemp.replace('${table}', xlsData).replace('${worksheet}', this.worksheet);
            },
            getKeys(data, header) {
                if (header) return header

                const keys = {}
                for (const key in data[0]) {
                    keys[key] = key
                }
                return keys
            },
            getProcessedJson(data, header) {
                const keys = this.getKeys(data, header);
                const newData = [];
                const _self = this;
                data.map(function(item) {
                    const newItem = {};
                    for (const label in keys) {
                        const property = keys[label];
                        newItem[label] = _self.getValue(property, item);
                    }
                    newData.push(newItem);
                });

                return newData;
            },
            getValueFromNestedItem(item, indexes){
                let nestedItem = item;
                for (const index of indexes) {
                    if(nestedItem) nestedItem = nestedItem[index];
                }
                return this.parseValue(nestedItem);
            },
            getValueFromCallback(item, callback){
                if(typeof callback !== 'function') return this.defaultValue
                const value = callback(item);
                return this.parseValue(value);
            },
            parseValue(value){
                return (value || value === 0 || typeof value === 'boolean') ? value : this.defaultValue
            },
            getValue(key, item) {
                const field = typeof key !== 'object' ? key : key.field;
                const indexes = typeof field !== 'string' ? [] : field.split('.');
                let value = this.defaultValue;
                
                if (!field) value = item;
                else if (indexes.length > 1) value = this.getValueFromNestedItem(item, indexes);
                else value = this.parseValue(item[field]);
                
                if (Object.prototype.hasOwnProperty.call(key, 'callback')) value = this.getValueFromCallback(value, key.callback);
                
                return value;
            },
            base64ToBlob(data, mime) {
                const base64 = window.btoa(window.unescape(encodeURIComponent(data)));
                const bstr = atob(base64);
                let n = bstr.length;
                const u8arr = new Uint8ClampedArray(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], { type: mime });
            },
            finishReview () {

                const reviewCount = this.hasRevisions()

                if (this.isAdmin) {
                    this.$router.push('/exams-available')
                } else if (reviewCount == -1) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.exams.review.warning.error'),
                        content: '',
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                        actionPrimary: { text: this.$t('actions.return'), callback() {} },
                        actionSecondary: null,
                    });
                } else if (reviewCount > 0) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.exams.review.warning.title'),
                        content: '',
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                        actionPrimary: { text: this.$t('modules.exams.review.warning.primaryAction'), callback: () => {this.sendReviewToBackend()} },
                        actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
                    });
                } else {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.exams.review.warning.finish_confirm'),
                        content: '',
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                        actionPrimary: { text: this.$t('modules.exams.review.answers.finish'), callback: () => {this.sendReviewToBackend(true)} },
                        actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
                    });
                }
            },
            async finishProctoring(){
                if (this.isKlarway) {
                    // Si estoy en el proctoring de klarway
                    const status = this.exam.calification >= 4 ? this.$t('modules.exams.review.brief.approved') : this.$t('modules.exams.review.brief.failed');
                   
                    const resp =  await $exam.finalizeExam(this.exam.calification, this.hash, status, '');
                    this.$store.dispatch('commons/initHash', resp.data.nk);
                    window.localStorage.setItem('hash', resp.data.nk);
                    // qualification, key, examStatus, force
                } else{
                    window.postMessage({monitoring: 'sumadiStop'});
                }
            },
            
            async sendReviewToBackend (withoutReview = false) {
                await this.finishProctoring()
                if (withoutReview) {
                    this.loading = true
                    this.exam = this.exam.answers.map((answer) => {
                        answer.reviewContent = '';
                        return answer
                    });

                    this.exam.review = false;
                    this.$router.push('/exams-available');
                    this.loading = false
                } else {
                    if (this.loading) return false
                    this.loading = true
                    this.onBeforeGenerate()
                    const json = this.getProcessedJson(this.json_data, this.fields)
                    const stringFile = this.jsonToXLS(json)
                    const blob = this.base64ToBlob(stringFile, 'application/vnd.ms-excel');
                    this.excelFile = new File([blob], `review${this.$route.params.id}`);
                    // download(this.excelFile, 'aaaaassda.xls')

                    try {
                        const formData = new FormData();
                        if (this.excelFile) formData.append('file', this.excelFile)
                        formData.append('userId', this.userId)
                        formData.append('examId', this.$route.params.id)
                        const options = Constants.getFetchOptions(formData, Constants.HTTP_METHOD_POST, 'multipart/form-data')
                        delete options.headers['Content-Type']
                        options.body = formData;
                        fetch(`${Constants.URL_BASE}/q-system/exams/${this.$route.params.id}/review`, options)
                            .then(data => {
                                if (data.status == 200) return data;
                                else throw new Error(data.message);
                            })
                        // await $exams.create({file: this.excelFile, userId: this.userId, examId: this.$route.params.id}, {}, `${this.$route.params.id}/review`);
                        this.$router.push('/exams-available');
                    } catch (error) {
                        this.$store.dispatch('commons/openPopUp', {
                            title: error.codeMeaning,
                            content: '',
                            icon: { color: 'error', name: 'mdi-close' },
                            color: 'primary',
                            actionSecondary: { text: this.$t('modules.exams.review.error.secondaryAction'), callback () {} },
                        });
                    } finally {
                        this.loading = false
                    }
                }
            },
            async getImage(id){
                try {
                    const {data} = await $multimedia.findById(id, 'image')
                    return `data:image/png;base64,${data}`
                } catch (error) {
                    return null
                }
            },
          
        },
        
        async mounted () {
            try {
                if (this.isKlarway) this.hash = this.getHash;
                const { data } = await $exams.findById(this.$route.params.id, 'justify');
                //Recoro el objeto de data para transformar el string en array de las preguntas correctas y seleccionadas, y asi armar una lista para mostrar en la vista (esto hace que se vea mas clara la respuesta multiple)
                data.answers.map(item => {
                    if (item.correctAnswer) {
                        item.correctAnswer = item.correctAnswer.split(' - ');
                    }
                    if(item.mediaCorrectAnswer ){
                      item.mediaCorrectAnswer.imagen = item.mediaCorrectAnswer.imagen.split(' - ');
                      item.mediaCorrectAnswer.images =  []              
                    }
                    if(item.mediaSelectedAnswer ){
                      item.mediaSelectedAnswer.imagen = item.mediaSelectedAnswer.imagen.split(' - ');             
                      item.mediaSelectedAnswer.images =  []            
                    }
                    return item;
                })
                
                this.exam = data;

                this.exam.answers.forEach((answer, i)=>{
                    answer.mediaSelectedAnswer?.imagen?.forEach(async (imagen) => {
                        const aux = await this.getImage(imagen).then(res => res)
                        this.exam.answers[i].mediaSelectedAnswer.images.push(aux)
                    }) 
                    answer.mediaCorrectAnswer?.imagen?.forEach(async (imagen) => {
                        const aux = await this.getImage(imagen).then(res => res)
                        this.exam.answers[i].mediaCorrectAnswer.images.push(aux)
                    }) 
                })
                    
            } catch (error) {
                this.$store.dispatch('commons/openPopUp', {
                    title: error.codeMeaning,
                    content: '',
                    actionSecondary: { text: this.$t('modules.exams.assestment.popUp.errorFinishingExam.buttonSecondary'), callback: () => {this.$router.push('/exams-available')} },
                    icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                    color: 'primary',
                });
            } finally {
                this.overlay = false;
            }
        }
    }
</script>

<style lang="sass" scoped>
.scrollableContent
    height: 100vh
    
.loading-message
    position: relative
    left: -4rem

.ExamRevision-card-rounded
    border-radius: 8px !important
    margin-top: 16px
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08)

.ExamRevision-expansion-panel
    border-radius: 8px !important
    box-shadow: none
    border: 1px solid rgba(0, 0, 0, 0.12)

    &::after
        opacity: 0 !important

.ExamRevision-expansion-panel-content
    border-top: 1px solid rgba(0, 0, 0, 0.12)
    padding-top: 10px
    padding-left: 50px
    padding-right: 50px

    .status-dot
        display: inline-block
        margin-right: 10px
        width: 12px
        height: 12px
        border-radius: 50%

    .ExamRevision-checkbox label.v-label
        color: black
</style>
