<template>
    <div class="loginFormCard fullHeightViewport">
        <div class="py-6 d-flex flex-column">
            <div class="d-flex justify-start">
                <v-img max-width="100px" height="auto" class="loginFormCard__logo" contain :src="logoPath"></v-img>
            </div>
            <div>
                <h3 class="display-1 primary--text">{{ recoveredData.title }}</h3>
            </div>
        </div>
        <v-alert text dense color="error" :value="recoveredData.authError" v-if="recoveredData.authError">
            {{ recoveredData.authErrorMessage }}
        </v-alert>
        <form @keyup.enter="recoveredAction" @submit.prevent="recoveredAction">
            <slot></slot>
            <div class="d-flex justify-space-between pt-3">
                <v-btn @click="goBack" class="px-6" text rounded large>Atrás</v-btn>
                <Button @clicked="recoveredAction" color="primary" class="px-6" :loading="loading" :error="errorAuth" error-text="Error" text="Siguiente" @end="$emit('update:errorAuth',false)" rounded large></Button>
            </div>
        </form>
    </div>
</template>
<script>
    import Button from '@/components/Button/Button'
    import { mapGetters } from 'vuex';
    
    export default {
        name: 'RecoveredForm',
        props: {
            loading: Boolean,
            recoveredData: Object,
            errorAuth: Boolean,
        },
        components:{
            Button
        },
        computed: {
            ...mapGetters({
                logoPath: 'commons/getLogoImageUrl',
            }),
        },
        methods: {
            recoveredAction () {
                this.$emit('recoveredAction');
            },
            goBack () {
                this.$router.go(-1)
            }
        }
    };
</script>
