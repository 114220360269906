import Vue from 'vue'
import VueI18n from 'vue-i18n'

import componentsTranslations from '@/components/components.i18n'
import { modulesTranslations } from '#/main.i18n.js'

import Configuration from '../constants/configuration';

Vue.use(VueI18n);

const messages = {
  es_CL:{
    modules: modulesTranslations.es_CL,
  },
  es_MX:{
    modules: modulesTranslations.es_MX,
  },
  es: {
    components: componentsTranslations.es,
    modules: modulesTranslations.es,
    responseCodeMeaning: {
      E200: 'Entidad creada correctamente.',
      E201: 'Entidad actualizada correctamente.',
      E202: 'Entidad eliminada correctamente.',
      E203: 'La entidad existe en el sistema.',
      E204: 'Los parámetros de consulta son inválidos.',
      E205: 'Los parámetros de consulta no pueden estar vacíos.',
      E206: 'No se encontró entidad con los parámetros provistos.',
      E207: 'Ocurrió un error al obtener listado.',
      E208: 'Ocurrió un error al obtener la entidad.',
      E209: 'Ocurrió un error al crear la entidad.',
      E210: 'Ocurrió un error al actualizar la entidad.',
      E211: 'Ocurrió un error al eliminar la entidad.',
      E212: 'No existe entidad por defecto para los párametros enviados.',
      E213: 'El código de validación es incorrecto.',
      E214: 'Correo enviado correctamente.',
      E215: 'SMS enviado correctamente.',
      E216: 'Ocurrió un error inesperado.',
      E217: 'Ocurrió un error en la validación.',
      default: 'Ha ocurrido un error.'
    },
    actions: {
      continue: 'Continuar',
      edit: 'Editar',
      delete: 'Eliminar',
      finish: 'Finalizar',
      add: 'Agregar',
      send: 'Enviar',
      sent: 'Enviado',
      save: 'Guardar',
      saved: 'Guardado',
      create: 'Crear',
      created: 'Creado',
      new: 'Nuevo',
      not_save: 'No guardar',
      cancel: 'Cancelar',
      accept: 'Aceptar',
      accepted: 'Aceptado',
      search: 'Buscar',
      back: 'Atrás',
      next: 'Siguiente',
      forgot: '¿Lo olvidaste?',
      goToHome: 'Ir a inicio',
      goToPage: 'Ir a página',
      confirmation_delete: '¿Esta seguro de eliminar?',
      return: 'Volver',
      error: 'Error',
      success: 'Exito',
    },
    enabled: 'Habilitado',
    disabled: 'Deshabilitado',
    delete_popup_title: '¿Esta seguro de eliminar?',
    disabledMessage: 'Estas cargando el registro de manera deshabilitada. ¿Desea continuar?',
    cancelMessage: 'Si cancela, se perderan los cambios realizados. ¿Desea continuar?',
    alert: 'Atención',
    home: {
      welcome_message: {
        title: 'Estamos creando contenido',
        description: 'Mientras, puedes navegar por el resto de la aplicación'
      }
    },
    helpMenu: {
      title: 'Ayuda',
      helpSection: {
        title: 'AYUDA',
        items: {
          documentation: 'Documentación',
          news: 'Novedades',
          about: 'Acerca de SIS',
          shortcuts: 'Atajos de teclado',
        }
      },
      legalSection: {
        title: 'ASUNTOS LEGALES',
        items: {
          privacyPolicy: 'Política de privacidad',
          termsConditions: 'Términos y condiciones',
        }
      }
    },

  },
  en: {
    components: componentsTranslations.en,
    modules: modulesTranslations.en,
    responseCodeMeaning: {
      E200: 'Entity created successfully.',
      E201: 'Entity updated successfully.',
      E202: 'Entity deleted successfully.',
      E203: 'The entity already exists on the system.',
      E204: 'Invalid query parameters.',
      E205: 'Query parameters cannot be empty.',
      E206: 'No matching entity for given parameters.',
      E207: 'An error ocurred while getting the list.',
      E208: 'An error ocurred while getting the entity.',
      E209: 'An error ocurred while creating the entity.',
      E210: 'An error ocurred while updating the entity.',
      E211: 'An error ocurred while deleting the entity.',
      E212: 'No default entity for given parameters.',
      E213: 'Invalid validation code.',
      E214: 'Email successfully sent.',
      E215: 'SMS successfully sent.',
      E216: 'An unexpected error ocurred.',
      default: 'An error occurred.'
    },
    actions: {
      send: 'Send',
      sent: 'Sent',
      save: 'Save',
      saved: 'Saved',
      create: 'Create',
      created: 'Created',
      new: 'New',
      accept: 'Accept',
      accepted: 'Accepted',
      search: 'Search',
      next: 'Next',
      forgot: 'You forgot it?',
      error: 'Error'
    },
  }
};


export const i18n = new VueI18n({
  locale: Configuration.value('lang') || 'es',
  fallbackLocale: 'es',
  silentFallbackWarn: true,
  messages,
});
