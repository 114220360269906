/**
 * @param  {object} body={}
 * @param  {string} method='POST'
 * @param  {boolean} authorization=false
 * @param  {string} contentType='application/json'
 */
export function createConfig(body = {}, method = 'POST', authorization = false, contentType = 'application/json') {
  const token = authorization
    ? 'cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0'
    : localStorage.getItem('access_token')
      ? JSON.parse(localStorage.getItem('access_token'))
      : '';
  const options = {
    method,
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': contentType,
      'Authorization': authorization ? `Basic ${token}` : `Bearer ${token}`,
    }
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  return options;
}
