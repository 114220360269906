const ipp = Object.freeze({
  primary: {
      base: '#012169',
      lighten5: '#274280',
      lighten4: '#4D6496',
      lighten3: '#8090B4',
      lighten2: '#B3BCD2',
      lighten1: '#E1E4ED',
      darken1: '#011D61',
      darken2: '#011856',
      darken3: '#01144C',
      darken4: '#000B3B',
      accent1: '#717EFF',
      accent2: '#3E50FF',
      accent3: '#0B22FF',
      accent4: '#0016F1'
  },
  secondary: {
      base: '#ED8B00',
      lighten5: '#F09C26',
      lighten4: '#F2AE4D',
      lighten3: '#F6C580',
      lighten2: '#FADCB3',
      lighten1: '#FDF1E0',
      darken1: '#EB8300',
      darken2: '#E87800',
      darken3: '#E56E00',
      darken4: '#E05B00',
      accent1: '#FFFFFF',
      accent2: '#FFE3D4',
      accent3: '#FFC1A1',
      accent4: '#FFB188'
  }
})

const teclab = Object.freeze({
  primary: {
      base: '#106ca7',
      lighten5: '#e2edf4',
      lighten4: '#b7d3e5',
      lighten3: '#88b6d3',
      lighten2: '#5898c1',
      lighten1: '#3482b4',
      darken1: '#0e649f',
      darken2: '#0c5996',
      darken3: '#094f8c',
      darken4: '#053d7c',
      accent1: '#aacdff',
      accent2: '#77aeff',
      accent3: '#4490ff',
      accent4: '#2b81ff'
  },
  secondary: {
      base: '#26a69a',
      lighten5: '#e5f4f3',
      lighten4: '#bee4e1',
      lighten3: '#93d3cd',
      lighten2: '#67c1b8',
      lighten1: '#47b3a9',
      darken1: '#229e92',
      darken2: '#1c9588',
      darken3: '#178b7e',
      darken4: '#0d7b6c',
      accent1: '#adfff3',
      accent2: '#7affec',
      accent3: '#47ffe4',
      accent4: '#2dffe0'
  }
})
const onmex = Object.freeze({
  primary: {
      base:     '#0056fe',
      lighten5:    '#e0ebff',
      lighten4:    '#b3ccff',
      lighten3:    '#80abff',
      lighten2:    '#4d89fe',
      lighten1:    '#266ffe',
      darken1:    '#004ffe',
      darken2:    '#0045fe',
      darken3:    '#003cfe',
      darken4:    '#002bfd',
      accent1:     '#fee13d',
      accent2:     '#fedd34',
      accent3:    '#fed92c',
      accent4:    '#fdd11e'
  },
  secondary: {
      base:     '#111a29',
      lighten5:    '#e2e4e5',
      lighten4:    '#b8babf',
      lighten3:    '#888d94',
      lighten2:    '#266ffe',
      lighten1:    '#353c49',
      darken1:    '#0f1724',
      darken2:    '#0c131f',
      darken3:    '#0a0f19',
      darken4:    '#05080f',
      accent1:     '#42e6eb',
      accent2:     '#39e3e8',
      accent3:    '#31dfe5',
      accent4:    '#21d9e0',
  }
})

export default Object.freeze({
  ipp,
  teclab,
  onmex
});