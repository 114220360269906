import Vue from 'vue'
import Router from 'vue-router'
import Base from './views/Base.vue'
import ModulesRouter from '../modules/main.router.js'
import LoginRoute from '../modules/Login/Routes'
import Login from '../modules/Login/Login'
import NotFoundState from './components/NotFoundState/NotFoundState'
import AmplifyForm from '../modules/Login/Components/AmplifyForm/AmplifyForm.vue'

import AuthService from './plugins/auth.service'
// import app from './main'
import store from '../store'

Vue.use(Router);
let initialLoaded = false

const router = new Router({
  mode: 'history',
  // eslint-disable-next-line no-process-env
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      meta: {requiresAuth: true, name: 'Base'},
      component: Base,
      children: ModulesRouter,
    },{
      path: '/login',
      meta: {name: 'login'},
      component: Login,
      children: LoginRoute
    },{
      path: '/login/login-amplify',
      meta: { name: 'loginAmplify'},
      component: AmplifyForm,
      children: LoginRoute
    },{
      path: '/logout',
      meta: {name: 'logout'},
    },{
      path: '*',
      meta: {requiresAuth: true, name: 'Base'},
      component: Base,
      children: [
        {
          path: '',
          component: NotFoundState
        }
      ],
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (AuthService.checkTokenExpired() || !AuthService.checkUserLoggedIn()) {
      next({path: '/login'})
    } else if (to.path == '/') {
      next({path: '/exams-available', query: { checkRegistry: true }})
    } else if (to.path == '/access-denied' || to.path == '/module-inactive') {
      next()
    } else {
      if (!initialLoaded) {
        await store.dispatch('commons/initData')
        initialLoaded = true
      }
      store.dispatch('commons/setLoadingDrawer', false);
      next()
      // if (app && app.$permissions) {
      //   const {path, permission} = to.meta
      //   if (path && permission !== undefined) {
      //     const perm = app.$permissions[path]
      //     if (!perm[permission]) next('/access-denied')
      //     else if (!perm.STATUS) next('/module-inactive')
      //     else next()
      //   } else {
      //     next({path: '/access-denied'})
      //   }
      // } else {
      //   next()
      // }
    }
  // } else if (to.path === '/login' && AuthService.checkUserLoggedIn() && !AuthService.checkTokenExpired()) {
  //   next(from.path)
  } else if (to.path === '/logout') {
    AuthService.logout()
  } else {
    next()
  }
});

export default router
